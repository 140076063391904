<div class="month-selector">
  <span>Month:</span>
  <div class="ranges-buttons">
    <ng-container *ngFor="let month of last3MonthsList">
      <p-button
        [label]="month"
        [outlined]="month === selectedMonth ? false : true"
        size="small"
        [severity]="month === selectedMonth ? 'primary' : 'secondary'"
        (onClick)="selectMonth(month)" />
    </ng-container>
  </div>

  <p-dropdown
    [options]="last11MonthsList"
    [(ngModel)]="selectedMonth"
    (ngModelChange)="selectMonth(selectedMonth)"
    placeholder="Select Month">
  </p-dropdown>
</div>
