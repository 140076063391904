import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.development';
import {
  FuelSource,
  RefuelAssetReqObject,
  RefuelRecord,
  RefuelTankReqObject,
  FuelAssetData,
  DepartmentLocation,
  FuelRecordPage,
} from '../interfaces';
import {
  IAllModulesResponse,
  ISubModulePreferenceRequest,
  ISubModuleResponse,
} from 'src/app/shared/interfaces';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Injectable({
  providedIn: 'root',
})
export class FuelService {
  http = inject(HttpClient);
  apiErrorService = inject(ApiErrorService);

  getFuelModules(): Observable<ISubModuleResponse[]> {
    return this.http
      .get<{
        sidebar: IAllModulesResponse[];
      }>(`${environment.baseUrl}/auth/modules`)
      .pipe(
        map(data => data.sidebar),
        map(item => item.filter(modules => modules.moduleName === 'Fuel')),
        switchMap(module => {
          return this.http
            .get<{
              button: ISubModuleResponse[];
            }>(`${environment.baseUrl}/auth/modules/${module[0].moduleId}`)
            .pipe(map(data => data.button));
        }),
        catchError(this.apiErrorService.handleError)
      );
  }

  updateSubModulePreference(
    modules: ISubModulePreferenceRequest[]
  ): Observable<{ [status: string]: string }> {
    return this.http
      .get<{
        sidebar: IAllModulesResponse[];
      }>(`${environment.baseUrl}/auth/modules`)
      .pipe(
        map(data => data.sidebar),
        map(item => item.filter(modules => modules.moduleName === 'Fuel')),
        switchMap(module => {
          return this.http
            .patch<{
              [status: string]: string;
            }>(
              `${environment.baseUrl}/auth/modules/${module[0].moduleId}/update-submodule-preference`,
              modules
            )
            .pipe(map(data => data));
        }),
        catchError(this.apiErrorService.handleError)
      );
  }

  getFuelSources(siteIds?: number[]): Observable<FuelSource[]> {
    const requestUrl = `${environment.baseUrl}/fuel-source/`;
    const params = this.getSiteIdsHttpParams(siteIds);
    return this.http
      .get<FuelSource[]>(requestUrl, { params: params })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  saveAssetRefuelRecord(data: RefuelAssetReqObject): Observable<RefuelRecord> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/asset`;
    return this.http
      .post<RefuelRecord>(requestUrl, data)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  saveTankRefuelRecord(data: RefuelTankReqObject): Observable<RefuelRecord> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/tank`;
    return this.http
      .post<RefuelRecord>(requestUrl, data)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getAssetDataGroupedByAssetClassName(
    siteIds?: number[]
  ): Observable<{ [assetClassName: string]: FuelAssetData[] }> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/getAssetDataGroupedByAssetClassName`;
    const params = this.getSiteIdsHttpParams(siteIds);
    return this.http
      .get<{
        [assetClassName: string]: FuelAssetData[];
      }>(requestUrl, { params: params })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getDepartmentAndLocations(
    siteIds?: number[]
  ): Observable<{ [type: string]: DepartmentLocation[] }> {
    const requestUrl = `${environment.baseUrl}/department-location/`;
    const params = this.getSiteIdsHttpParams(siteIds);
    return this.http
      .get<{
        [type: string]: DepartmentLocation[];
      }>(requestUrl, { params: params })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  adjustTankValues(
    fuelSourceId: number,
    data: { [key: string]: number }
  ): Observable<FuelSource> {
    const requestUrl = `${environment.dataRestUrl}/fuel-source/${fuelSourceId}`;
    return this.http
      .patch<FuelSource>(requestUrl, data)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getFuelRefillReport(
    startDate: string,
    endDate: string,
    page: number,
    size: number,
    sortOptions: { sort: 'asc' | 'desc'; colId: string }[],
    filterOptions: { [column: string]: { filter: string } },
    siteIds?: number[]
  ): Observable<FuelRecordPage> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/report`;
    let params = this.getSiteIdsHttpParams(siteIds)
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('page', page)
      .append('size', size);
    params = params.appendAll(
      this.getSortingAndFilterParams(sortOptions, filterOptions)
    );
    return this.http
      .get<FuelRecordPage>(requestUrl, { params: params })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getSortingAndFilterParams(
    sortOptions: { sort: 'asc' | 'desc'; colId: string }[],
    filterOptions: { [column: string]: { filter: string } }
  ): { [paramKey: string]: string | boolean } {
    const filterObject = Object.keys(filterOptions).reduce(
      (acc, key) => {
        const searchElement = filterOptions[key].filter.trim();
        if (searchElement.length > 0) {
          acc[key] = filterOptions[key].filter;
        }
        return acc;
      },
      {} as { [key: string]: string }
    );
    if (sortOptions.length > 0) {
      return {
        ...filterObject,
        sortAsc: sortOptions[0].sort === 'asc',
        sortColumn: sortOptions[0].colId,
      };
    } else {
      return filterObject;
    }
  }

  getSiteIdsHttpParams(siteIds?: number[]): HttpParams {
    const param = new HttpParams();
    if (siteIds === undefined) {
      return param;
    } else {
      return param.set('siteIds', siteIds.toString());
    }
  }

  updateFuelRefill(
    data: RefuelRecord
  ): Observable<{ [status: string]: string }> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/updateFuelRefill`;
    return this.http
      .put<{ [status: string]: string }>(requestUrl, data)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  deleteFuelRefill(id: number): Observable<{ [status: string]: string }> {
    const requestUrl = `${environment.baseUrl}/fuel-refill/deleteFuelData/${id}`;
    return this.http
      .delete<{ [status: string]: string }>(requestUrl)
      .pipe(catchError(this.apiErrorService.handleError));
  }
}
