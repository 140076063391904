import { Action, createReducer, on } from '@ngrx/store';
import { IMessageStatus } from 'src/app/shared/interfaces';
import * as fromActions from './asset.action';
import { IAsset } from '../interfaces/asset.interface';

export interface AssetState {
  messageStatus: IMessageStatus | null;
  assetList?: IAsset[] | null;
  asset?: IAsset | null;
}

const initialState: AssetState = {
  messageStatus: null,
};

export const _assetReducer = createReducer(
  initialState,
  on(fromActions.resetMessageStatus, (state): AssetState => {
    return {
      ...state,
      messageStatus: null,
    };
  }),
  on(fromActions.getAllAssetsSuccess, (state, action): AssetState => {
    return {
      ...state,
      assetList: action.assetsList,
    };
  }),
  on(fromActions.getAllAssetsFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.getAssetByIdSuccess, (state, action): AssetState => {
    return {
      ...state,
      asset: action.asset,
    };
  }),
  on(fromActions.getAssetByIdFailure, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.createNewAssetSuccess, (state, action): AssetState => {
    return {
      ...state,
      assetList: [...(state.assetList ?? []), action.asset],
      asset: action.asset,
      messageStatus: {
        type: 'success',
        message: 'Asset Created Successfully',
      },
    };
  }),
  on(fromActions.createNewAssetFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.updateAssetSuccess, (state, action): AssetState => {
    return {
      ...state,
      assetList: state.assetList?.map(asset => {
        if (asset.id == action.asset.id) {
          return { ...asset, name: action?.asset?.name };
        }
        return asset;
      }),
    };
  }),
  on(fromActions.updateAssetFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.deleteAssetSuccess, (state, action): AssetState => {
    return {
      ...state,
      assetList: state.assetList?.filter(asset => asset.id !== action.assetId),
      asset: state.asset?.id == action.assetId ? null : state.asset,
      messageStatus: {
        type: 'success',
        message: 'Asset Deleted Successfully',
      },
    };
  }),
  on(fromActions.deleteAssetFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(
    fromActions.updateAssetStatusSuccess,
    (state, { newStatus }): AssetState => {
      return {
        ...state,
        assetList: state.assetList?.map(asset =>
          newStatus[asset.id] !== undefined
            ? { ...asset, status: newStatus[asset.id] }
            : asset
        ),
        asset:
          state.asset && newStatus[state.asset.id] !== undefined
            ? { ...state.asset, status: newStatus[state.asset.id] }
            : state.asset,
      };
    }
  ),
  on(fromActions.updateAssetStatusFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.uploadAssetImageSuccess, (state, action): AssetState => {
    return {
      ...state,
      asset: state.asset
        ? {
            ...state?.asset,
            profilePictures: [
              ...(state?.asset?.profilePictures ?? []),
              ...action.imgUrls,
            ],
          }
        : state?.asset,
    };
  }),
  on(fromActions.uploadAssetImageFailed, (state, action): AssetState => {
    return {
      ...state,
      messageStatus: {
        type: 'error',
        message: action.message,
      },
    };
  }),
  on(fromActions.updateAssetTagValuesFailed, (state, action): AssetState => {
    if (!action?.asset) return state;

    return {
      ...state,
      assetList: state.assetList?.map(asset => {
        if (asset.id == action.asset?.id) {
          return { ...asset, name: action.asset.name };
        }
        return asset;
      }),
    };
  }),
  on(fromActions.clearAssetStore, (state): AssetState => {
    return {
      ...state,
      asset: null,
      messageStatus: null,
      assetList: null,
    };
  })
);

export function assetReducer(state: AssetState | undefined, action: Action) {
  return _assetReducer(state, action);
}
