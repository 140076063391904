import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './asset.reducer';
import { IAsset, AssetGrpByClassBySite } from '../interfaces/asset.interface';

export const ASSET_STATE_NAME = 'asset';

export const selectAssetState =
  createFeatureSelector<fromReducer.AssetState>(ASSET_STATE_NAME);

export const selectAssetsGroupByClass = createSelector(
  selectAssetState,
  state => {
    if (!state.assetList) return {};

    const groupBySite = groupByKey(state.assetList, 'siteName');
    const groupByAssetClass: AssetGrpByClassBySite = {};
    for (const key in groupBySite) {
      groupByAssetClass[key] = groupByKey(groupBySite[key], 'assetClassName');
    }
    return groupByAssetClass;
  }
);

export const selectAsset = createSelector(
  selectAssetState,
  state => state.asset
);
/*


  any can be fixed but will bring more confusion to code
*/
const groupByKey = (list: IAsset[], key: string) =>
  list.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (hash: any, obj: any) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

export const selectAssetList = createSelector(
  selectAssetState,
  state => state.assetList
);
