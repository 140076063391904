import { Component } from '@angular/core';

@Component({
  selector: 'app-aside-loading-data',
  template: `
    <div class="empty-content">
      <p class="empty-heading">... Loading</p>
    </div>
  `,
  styles: [
    `
      .empty-content {
        text-align: center;
        margin-top: 40%;
        font-weight: 500;
      }

      .empty-heading {
        font-size: 1.9rem;
        color: #939090;
        margin: 1rem 0 0;
      }
    `,
  ],
})
export class AsideLoadingDataComponent {}
