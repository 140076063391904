import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { TimeFrame } from '../interfaces';

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
})
export class MonthSelectComponent implements OnInit {
  @Output() dateChanged = new EventEmitter<TimeFrame>();

  last11MonthsList: string[] = [];
  last3MonthsList: string[] = [];
  selectedMonth: string;

  ngOnInit(): void {
    this.last11MonthsList = this.generateMonthList(3, 11);
    this.last3MonthsList = this.generateMonthList(0, 2);
    this.selectedMonth = this.last3MonthsList[0];
    this.dateChanged.emit({
      startDate: DateTime.now().startOf('month').toISO(),
      endDate: DateTime.now().toISO(),
      month: DateTime.now().toFormat('MMMM'),
    });
  }

  private generateMonthList(startOffset: number, endOffset: number): string[] {
    return Array.from({ length: endOffset - startOffset + 1 }, (_, i) =>
      DateTime.now()
        .minus({ months: startOffset + i })
        .toFormat('LLL yy')
    );
  }

  selectMonth(month: string) {
    this.selectedMonth = month;

    const date = DateTime.fromFormat(month, 'LLL yy');

    this.dateChanged.emit({
      startDate: date.startOf('month').toISO() ?? '',
      endDate: date.endOf('month').toISO() ?? '',
      month: date.monthLong ?? '',
    });
  }
}
