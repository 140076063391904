import { Component, Input, OnInit } from '@angular/core';
import { IChartData } from '../../interfaces';
import * as Highcharts from 'highcharts';

import noDataToDisplay from 'highcharts/modules/no-data-to-display';
noDataToDisplay(Highcharts);

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
})
export class ChartLineComponent implements OnInit {
  @Input({ required: true }) set plottingData(chartDataArr: IChartData[]) {
    chartDataArr.map(chartData => {
      this._plottingData = {
        ...this._plottingData,
        [chartData.propertyName]: chartData.aggregatedData,
      };
    });

    this.processChartData();
    this.updateChartData();
  }

  HighChart: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  chartData: Highcharts.SeriesOptionsType[] = [];
  chartXKeys: string[] = [];
  month: string;
  lineColors = ['#ff9f0a', '#63a1fd', '#aec0db'];

  _plottingData: { [key: string]: { [date: string]: number } } = {};

  ngOnInit() {
    this.processChartData();
    this.initChartOptions();
  }

  private processChartData(): void {
    this.chartData = [];
    const uniqueDates = new Set<string>();

    Object.entries(this._plottingData).forEach(([key, data]) => {
      Object.keys(data).forEach(date => uniqueDates.add(date));

      const value: Highcharts.SeriesOptionsType = {
        name: key,
        type: 'line',
        data: Object.values(data).map(value => value),
      };
      this.chartData.push(value);
    });

    if (!uniqueDates.size) return;

    this.chartXKeys = Array.from(uniqueDates).sort(
      (a, b) => parseInt(a.split(' ')[0]) - parseInt(b.split(' ')[0])
    );

    this.month = this.chartXKeys[0].split(' ')[0] ?? '';
  }

  initChartOptions(): void {
    this.chartOptions = {
      accessibility: { enabled: false },
      title: { text: '' },
      series: this.chartData,
      colors: this.lineColors,
      tooltip: { shared: true },
      credits: { enabled: false },
      plotOptions: { line: { marker: { symbol: 'circle' } } },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        floating: false,
        itemStyle: { opacity: 0.6 },
      },
      lang: { noData: 'No Data Available for the Selected Criteria' },
      noData: {
        style: { fontWeight: '500', fontSize: '16px', color: '#303030' },
      },
      chart: {
        height: 400,
        type: 'line',
        zooming: { type: 'x' },
        panKey: 'shift',
        style: { fontSize: '16', fontFamily: 'poppins', fontWeight: '500' },
      },
      xAxis: {
        title: { text: this.month ?? 'Month', style: { opacity: 0.7 }, y: 5 },
        labels: { style: { opacity: 0.6, fontSize: '10px' }, rotation: 90 },
        categories: this.chartXKeys,
        type: 'category',
        crosshair: { width: 60, color: '#62a1fb17', dashStyle: 'Solid' },
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        gridLineColor: '#00000012',
        lineWidth: 3,
        lineColor: '#d9d9d9',
        alignTicks: true,
        tickPosition: 'inside',
        tickColor: '#e0e0e0',
        tickWidth: 2,
        tickLength: 20,
        tickmarkPlacement: 'on',
        offset: 30,
      },
      yAxis: {
        labels: { enabled: true },
        title: { text: 'Value', style: { opacity: 0.6 } },
        gridLineDashStyle: 'Dash',
        gridLineColor: '#696969d6',
        lineWidth: 1,
        lineColor: '#e0e0e0',
        alignTicks: true,
        tickColor: '#e0e0e0',
        tickWidth: 2,
      },
    };
  }

  updateChartData(): void {
    this.chartOptions = { ...this.chartOptions, series: this.chartData };
  }
}
