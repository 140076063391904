import { Component, OnInit, Input } from '@angular/core';
import { Geofence } from '../interfaces/map.interfaces';
import { Observable, Subject, Subscription, combineLatest, skip } from 'rxjs';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  map: google.maps.Map;
  mapOptions: google.maps.MapOptions;
  private subscription!: Subscription;

  @Input() fenceList!: Subject<Geofence[]>;
  @Input() isEnableOnMap!: Observable<boolean>;

  geofences: Geofence[] = [];

  ngOnInit(): void {
    this.setMapCenter();

    // Combine fenceList and isEnableOnMap observables
    this.subscription = combineLatest([
      this.fenceList.pipe(skip(1)),
      this.isEnableOnMap.pipe(skip(1)),
    ]).subscribe(([geofences, enabled]) => {
      this.geofences.push(...geofences); // Update the geofences list
      this.displayGeofencesOnMap(enabled); // Display geofences if enabled
    });
  }

  setMapCenter() {
    this.mapOptions = {
      center: { lat: 22.1851144, lng: 83.5023645 }, // Set to your desired coordinates
      zoom: 8,
      mapId: '62304de93ee45a67', // MapID from Google Map Console
    };
    this.map = new google.maps.Map(
      document.getElementById('googleMap') as HTMLElement,
      this.mapOptions
    );
  }

  displayGeofencesOnMap(enabled: boolean) {
    // Clear existing geofence overlays
    this.geofences.forEach(geofence => {
      if (geofence.mapOverlay) {
        geofence.mapOverlay.setMap(null); // Remove the overlay from the map
      }
    });

    // If no geofences or geofences are disabled, return
    if (this.geofences.length === 0 || !enabled) {
      return;
    }

    // Create a LatLngBounds object to adjust the view to fit all geofences
    const bounds = new google.maps.LatLngBounds();

    this.geofences.forEach(geofence => {
      let mapOverlay = geofence.mapOverlay; // Get the existing map overlay if any

      // If mapOverlay is already defined, reuse it
      if (!mapOverlay) {
        const coordinates = geofence.geofenceCoordinates;

        // If radius is non-zero, treat it as a circle
        if ((geofence.radius ?? 0) > 0) {
          const geofenceCenter = new google.maps.LatLng(
            coordinates[0].latitude,
            coordinates[0].longitude
          );

          // Create a new circle overlay
          mapOverlay = new google.maps.Circle({
            strokeColor: geofence.color || '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: geofence.color || '#FF0000',
            fillOpacity: 0.35,
            center: geofenceCenter,
            radius: geofence.radius,
          });
          geofence.mapOverlay = mapOverlay; // Assign the new overlay

          bounds.extend(geofenceCenter); // Extend bounds to include the circle center
        }
        // If radius is zero and we have multiple coordinates, treat it as a polygon
        else {
          const polygonPath = coordinates.map(
            (coord: { latitude: number; longitude: number }) =>
              new google.maps.LatLng(coord.latitude, coord.longitude)
          );

          // Create a new polygon overlay
          mapOverlay = new google.maps.Polygon({
            paths: polygonPath,
            strokeColor: '#FFD32C',
            strokeOpacity: 0.8,
            strokeWeight: 8,
            fillColor: '#FFD32C',
            fillOpacity: 0.35,
          });
          geofence.mapOverlay = mapOverlay; // Assign the new overlay

          polygonPath.forEach(coord => bounds.extend(coord)); // Extend bounds for each coordinate in the polygon
        }
      }
      mapOverlay.setMap(enabled ? this.map : null);
    });

    // Fit the map to the bounds after all geofences are added, if the bounds are valid
    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds);
    }
  }
}
