import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AssetService } from '../services/asset.service';

import * as fromAction from './asset.action';
import { ToastService } from 'src/app/core/services/toast.service';
import {
  ASSET_BASE_URL,
  ASSET_FILE_CATG,
} from 'src/app/configuration/constants/constants';
import { selectAsset, selectAssetList } from './';
import { Router } from '@angular/router';

@Injectable()
export class AssetEffects {
  action$ = inject(Actions);
  store = inject(Store);
  assetService = inject(AssetService);
  toastService = inject(ToastService);
  router = inject(Router);

  loadAllAssets$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.getAllAssets),
      concatLatestFrom(() => this.store.select(selectAssetList)),
      switchMap(([, assetList]) => {
        if (assetList) {
          return of(fromAction.getAllAssetsSuccess({ assetsList: assetList }));
        }

        return this.assetService.getAllAssets().pipe(
          map(data => fromAction.getAllAssetsSuccess({ assetsList: data })),
          catchError(error => {
            return of(
              fromAction.getAllAssetsFailed({
                message: error.error.message,
              })
            );
          })
        );
      })
    );
  });

  loadAssetById$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.getAssetById),
      concatLatestFrom(() => this.store.select(selectAsset)),
      switchMap(([action, asset]) => {
        if (asset?.id == action.assetId) {
          return of(
            fromAction.getAssetByIdSuccess({
              asset: asset,
              shouldNavigate: action?.shouldNavigate,
            })
          );
        }

        return this.assetService.getAssetById(action.assetId).pipe(
          map(data =>
            fromAction.getAssetByIdSuccess({
              asset: data,
              shouldNavigate: action?.shouldNavigate,
            })
          ),
          catchError(error => {
            return of(
              fromAction.getAssetByIdFailure({ message: error.error.message })
            );
          })
        );
      })
    );
  });

  navigateAfterLoad$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(fromAction.getAssetByIdSuccess),
        filter(action => action?.shouldNavigate === true),
        tap(() => {
          if (!this.isNavigating) {
            this.isNavigating = true;
            this.router.navigate([`${ASSET_BASE_URL}/detail`]).finally(() => {
              this.isNavigating = false;
            });
          }
        })
      );
    },
    { dispatch: false }
  );

  createAsset$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.createNewAsset),
      switchMap(action =>
        this.assetService.createAsset(action.assetData).pipe(
          switchMap(newAsset => {
            if (action.tags.length > 0) {
              return this.assetService
                .updateAssetTagValues(newAsset.id, action.tags)
                .pipe(
                  map(() =>
                    fromAction.createNewAssetSuccess({ asset: newAsset })
                  ),
                  catchError(error =>
                    of(
                      fromAction.updateAssetTagValuesFailed({
                        asset: newAsset,
                        message: error.error.message,
                      })
                    )
                  )
                );
            }
            return of(fromAction.createNewAssetSuccess({ asset: newAsset }));
          }),
          tap(() =>
            this.toastService.showToastMessage(
              'Success',
              'Asset Created Successfully',
              'success'
            )
          ),
          catchError(error =>
            of(
              fromAction.createNewAssetFailed({
                message: error.error.message,
              })
            )
          )
        )
      )
    );
  });

  updateAsset$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.updateAsset),
      switchMap(action =>
        this.assetService.updateAsset(action.assetId, action.asset).pipe(
          map(updatedAsset =>
            fromAction.updateAssetSuccess({
              asset: { ...updatedAsset, id: action.assetId },
            })
          ),
          tap(() =>
            this.toastService.showToastMessage(
              'Success',
              'Asset Updated Successfully',
              'success'
            )
          ),
          catchError(error =>
            of(
              fromAction.updateAssetFailed({
                message: error.error.message,
              })
            )
          )
        )
      )
    );
  });

  deleteAsset$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.deleteAsset),
      switchMap(action => {
        return this.assetService.deleteAsset(action.assetId).pipe(
          tap(res =>
            this.toastService.showToastMessage(
              'Success',
              res.success,
              'success'
            )
          ),
          map(() => fromAction.deleteAssetSuccess({ assetId: action.assetId })),
          catchError(error =>
            of(
              fromAction.deleteAssetFailed({
                message: error?.error?.message,
              })
            )
          )
        );
      })
    );
  });

  updateAssetStatus$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.updateAssetStatus),
      switchMap(action => {
        return this.assetService.updateAssetStatus(action.asset).pipe(
          tap(() =>
            this.toastService.showToastMessage(
              'Success',
              'Status Updated Successfully',
              'success'
            )
          ),
          map(res => fromAction.updateAssetStatusSuccess({ newStatus: res })),
          catchError(error =>
            of(
              fromAction.updateAssetStatusFailed({
                message: error?.error?.message,
              })
            )
          )
        );
      })
    );
  });

  uploadAssetImage$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromAction.uploadAssetImage),
      switchMap(action => {
        const fileUploadObservables = action.files.map(file =>
          this.assetService
            .getPreSignedUrl({
              assetId: action.assetId,
              fileName: file.name,
            })
            .pipe(
              switchMap(assetDocument =>
                this.assetService
                  .uploadToS3(assetDocument.preSignedUrl, file)
                  .pipe(
                    switchMap(() =>
                      this.assetService.storeS3UrlToDb({
                        assetId: action.assetId,
                        fileName: assetDocument.fileName,
                        fileCategory: ASSET_FILE_CATG.PROFILE_PICTURE,
                        s3Link: assetDocument.s3Key,
                      })
                    )
                  )
              )
            )
        );

        return forkJoin(fileUploadObservables).pipe(
          map(documents => {
            const imgUrls = documents.map(doc => doc.s3Link);
            return fromAction.uploadAssetImageSuccess({ imgUrls });
          }),
          catchError(error =>
            of(
              fromAction.uploadAssetImageFailed({
                message: error?.error?.message,
              })
            )
          )
        );
      })
    );
  });
  isNavigating: boolean;
}
