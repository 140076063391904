import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-period-select',
  templateUrl: './time-period-select.component.html',
  styleUrls: ['./time-period-select.component.scss'],
})
export class TimePeriodSelectComponent implements OnInit {
  @Input() dateRanges: number[];
  @Input() defaultRange: number;
  @Output() dateChanged = new EventEmitter<{
    fromDate: string;
    toDate: string;
  }>();

  rangeDates: Date[];
  customDateSelect = false;

  toDate: Date;
  fromDate: Date;

  selectedRange = 0;

  ngOnInit(): void {
    this.rangeSelected(this.defaultRange);
  }

  rangeSelected(range: number) {
    if (this.selectedRange != range) {
      this.selectedRange = range;
      const nowDate = new Date();
      const fromDate = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth(),
        nowDate.getDate() - range
      );
      nowDate.setHours(23, 59, 59);
      this.updateDateRanges(fromDate, nowDate);
      this.dateChanged.emit({
        fromDate: fromDate.toISOString(),
        toDate: nowDate.toISOString(),
      });
    }
  }

  onCustomRangeSelected(range: Date[]) {
    if (range.length === 2 && range[1] !== null) {
      this.updateDateRanges(range[0], range[1]);
      this.selectedRange = 0;
      this.dateChanged.emit({
        fromDate: range[0].toISOString(),
        toDate: range[1].toISOString(),
      });
    }
  }

  onRangeSelectClosed() {
    this.rangeDates = [this.fromDate, this.toDate];
  }

  updateDateRanges(fromDate: Date, toDate: Date) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.rangeDates = [this.fromDate, this.toDate];
  }
}
