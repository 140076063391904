import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from './auth.action';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthEffects {
  action$ = inject(Actions);
  authService = inject(AuthService);
  router = inject(Router);
  location = inject(Location);

  login$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromActions.loginStart),
      exhaustMap(action => {
        return this.authService
          .login({
            username: action.username,
            password: action.password,
          })
          .pipe(
            map(res => {
              const userData = this.authService.extractUserDataFromToken(
                res.token
              );
              if (userData !== null) {
                this.authService.handleLogin(userData);
                return fromActions.loginSuccess({
                  userId: userData.userId,
                  userName: userData.userName,
                  roles: userData.roles,
                });
              } else {
                throw new Error('Invalid token');
              }
            }),
            catchError(error => {
              return of(fromActions.loginFailure({ error: error.message }));
            })
          );
      })
    );
  });

  autoAuthenticate$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromActions.autoAuthenticate),
      map(() => {
        const user = this.authService.getAuthDataFromLocalStorage();
        if (user != null) {
          this.authService.onSuccessfulAuthentication(user.expiration);
          return fromActions.loginSuccess({
            userId: user.userId,
            userName: user.userName,
            roles: user.roles,
          });
        } else {
          throw new Error('User data not found in local storage');
        }
      }),
      catchError(() => {
        this.authService.logout();
        return of(fromActions.loginFailure({ error: 'Login Failed' }));
      })
    );
  });

  logout$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(fromActions.logout),
        tap(() => {
          this.authService.logout();
          this.router.navigate(['/']);
        })
      );
    },
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(fromActions.loginSuccess),
        map(() => {
          const currentRoute = this.location.path();
          currentRoute === '/auth'
            ? this.router.navigate(['maintenance', 'home'])
            : this.router.navigate([currentRoute]);
        })
      );
    },
    { dispatch: false }
  );
}
