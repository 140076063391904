import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import {
  AssetDataRest,
  AssetDocument,
  AssetsPaginationRes,
  EdgeDevice,
  IAsset,
  IAssetComponent,
  IAssetReq,
  PreSignedUrl,
} from '../interfaces/asset.interface';
import { environment } from 'src/environments/environment.development';
import { ApiErrorService } from '../../core/services/api-error.service';
import { PaginationReqDTO } from 'src/app/shared/interfaces';
import { ITagValue } from 'src/app/configuration/interfaces/configuration.interfaces';
import { TAG_INPUT_TYPES } from 'src/app/configuration/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  http = inject(HttpClient);
  apiErrorService = inject(ApiErrorService);

  getPaginatedAssets(
    reqBody: PaginationReqDTO
  ): Observable<AssetsPaginationRes> {
    return this.http
      .post<AssetsPaginationRes>(`${environment.baseUrl}/asset/query`, reqBody)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getAllAssets(): Observable<IAsset[]> {
    const requestUrl = `${environment.baseUrl}/asset`;
    return this.http
      .get<IAsset[]>(requestUrl)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getAssetById(assetId: number): Observable<IAsset> {
    const requestUrl = `${environment.baseUrl}/asset/${assetId}`;
    return this.http
      .get<IAsset>(requestUrl)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  createAsset(assetData: IAssetReq): Observable<IAsset> {
    const requestUrl = `${environment.baseUrl}/asset`;
    return this.http
      .post<IAsset>(requestUrl, assetData)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getTagValuesByAssetAndClass(
    assetId: number,
    assetClassId: number
  ): Observable<{ [key: string]: ITagValue[] }> {
    return this.http
      .get<{
        [key: string]: ITagValue[];
      }>(
        `${environment.baseUrl}/tag/asset-class/values?asset=${assetId}&assetClass=${assetClassId}`
      )
      .pipe(
        map((tagValueGroup: { [key: string]: ITagValue[] }) =>
          this.transformOptionValueType(tagValueGroup)
        ),
        catchError(this.apiErrorService.handleError)
      );
  }

  getAssetTagValues(
    assetId: number
  ): Observable<{ [key: string]: ITagValue[] }> {
    return this.http
      .get<{
        [key: string]: ITagValue[];
      }>(`${environment.baseUrl}/tag/values/${assetId}`)
      .pipe(
        map((tagValueGroup: { [key: string]: ITagValue[] }) =>
          this.transformOptionValueType(tagValueGroup)
        ),
        catchError(this.apiErrorService.handleError)
      );
  }

  transformOptionValueType(tagValueGroup: { [key: string]: ITagValue[] }): {
    [key: string]: ITagValue[];
  } {
    Object.values(tagValueGroup).forEach((tagValues: ITagValue[]) => {
      tagValues.forEach((tagValue: ITagValue) => {
        if (
          tagValue.tagInputType == TAG_INPUT_TYPES.SELECT &&
          typeof tagValue.optionValue === 'string'
        ) {
          tagValue.optionValue = tagValue.optionValue?.split(',');
        }
      });
    });
    return tagValueGroup;
  }

  updateAssetTagValues(
    assetId: number,
    tagValues: ITagValue[]
  ): Observable<ITagValue[]> {
    return this.http
      .post<
        ITagValue[]
      >(`${environment.baseUrl}/tag/values/${assetId}`, tagValues)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  updateAssetStatus(asset: IAsset): Observable<{ [key: number]: string }> {
    return this.http
      .put<{
        [key: number]: string;
      }>(`${environment.baseUrl}/asset/status`, asset)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getAllAvailableEdgeDevices(): Observable<EdgeDevice[]> {
    return this.http.get<EdgeDevice[]>(
      `${environment.baseUrl}/asset/edge-devices`
    );
  }

  deleteAsset(assetId: number): Observable<{ success: string }> {
    return this.http
      .delete<{ success: string }>(`${environment.baseUrl}/asset/${assetId}`)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  // get presigned url of s3 to send data to s3
  getPreSignedUrl(reqDto: {
    assetId: number;
    fileName: string;
  }): Observable<PreSignedUrl> {
    return this.http
      .post<PreSignedUrl>(
        `${environment.baseUrl}/document/pre-signed-url`,
        reqDto
      )
      .pipe(catchError(this.apiErrorService.handleError));
  }

  // upload image to s3
  uploadToS3(presignedUrl: string, file: File): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': file.type,
    });

    return this.http
      .put<void>(presignedUrl, file, { headers: headers })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  // upload asset image url to db for profile pictures retrieval
  storeS3UrlToDb(reqDto: AssetDocument): Observable<AssetDocument> {
    return this.http
      .post<AssetDocument>(
        `${environment.baseUrl}/document/upload-document-data`,
        reqDto
      )
      .pipe(catchError(this.apiErrorService.handleError));
  }

  updateAsset(assetId: number, asset: IAssetReq): Observable<IAsset> {
    const buildEntityIDUrl = (endpoint: string, id: number | string) =>
      `${environment.dataRestUrl}/${endpoint}/${id}`;

    const assetReq: IAssetReq & AssetDataRest = { ...asset };

    if (asset.geofenceIds?.length) {
      assetReq.geofenceList = asset.geofenceIds.map(id =>
        buildEntityIDUrl('geofences', id)
      );
    }

    if (asset?.siteId > 0) {
      assetReq.site = buildEntityIDUrl('sites', asset.siteId);
    }

    if (asset?.assetClassId > 0) {
      assetReq['assetClass'] = buildEntityIDUrl(
        'assetClasses',
        asset.assetClassId
      );
    }

    if (asset?.imeiNumber !== undefined) {
      assetReq.imeiEdgeDevice = buildEntityIDUrl(
        'imeiEdgeDevices',
        asset.imeiNumber
      );
    }

    return this.http
      .patch<IAsset>(buildEntityIDUrl('assets', assetId), assetReq)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  getAssetComponent(assetId: number): Observable<IAssetComponent> {
    return this.http
      .get<IAssetComponent>(
        `${environment.dataRestUrl}/assetComponents/search/findByAsset_Id?assetId=${assetId}`
      )
      .pipe(catchError(this.apiErrorService.handleError));
  }

  addAssetComponent(
    assetComponent: IAssetComponent
  ): Observable<IAssetComponent> {
    assetComponent = {
      ...assetComponent,
      asset: `${environment.dataRestUrl}/asset/${assetComponent.assetId}`,
    };

    return this.http
      .post<IAssetComponent>(
        `${environment.dataRestUrl}/assetComponents`,
        assetComponent
      )
      .pipe(catchError(this.apiErrorService.handleError));
  }

  updateAssetComponent(
    resourceUrl: string,
    assetComponent: IAssetComponent
  ): Observable<IAssetComponent> {
    return this.http
      .patch<IAssetComponent>(resourceUrl, assetComponent)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  deleteAssetComponent(resourceUrl: string): Observable<void> {
    return this.http
      .delete<void>(resourceUrl)
      .pipe(catchError(this.apiErrorService.handleError));
  }
}
