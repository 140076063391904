import { Injectable, InjectionToken, PLATFORM_ID, inject } from '@angular/core';
import { IPasscode } from '../interfaces';

export const LOCAL_STORAGE = new InjectionToken<Storage>(
  'window local storage object',
  {
    providedIn: 'root',
    factory: () => {
      return inject(PLATFORM_ID) === 'browser'
        ? window.localStorage
        : ({} as Storage);
    },
  }
);

const LOCAL_STORAGE_KEY_FOR_TOKEN = 'token';
const LOCAL_SOTRAGE_PASSCODE_OPERATOR = 'operator_data';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage = inject(LOCAL_STORAGE);

  loadAuthToken() {
    const token = this.storage.getItem(LOCAL_STORAGE_KEY_FOR_TOKEN);
    return token;
  }

  saveAuthToken(token: string) {
    this.storage.setItem(LOCAL_STORAGE_KEY_FOR_TOKEN, token);
  }

  clearAuthToken() {
    this.storage.removeItem(LOCAL_STORAGE_KEY_FOR_TOKEN);
    this.clearPasscodeOperatorData();
  }

  savePasscodeOperatorData(operator: IPasscode) {
    const data = {
      operatorId: operator.id,
      name: operator.operatorName,
    };
    this.storage.setItem(LOCAL_SOTRAGE_PASSCODE_OPERATOR, JSON.stringify(data));
  }

  loadPasscodeOperatorData() {
    const operatorData = this.storage.getItem(LOCAL_SOTRAGE_PASSCODE_OPERATOR);
    return operatorData;
  }

  clearPasscodeOperatorData() {
    this.storage.removeItem(LOCAL_SOTRAGE_PASSCODE_OPERATOR);
  }
}
