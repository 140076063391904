import { Component, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss'],
})
export class MapToolbarComponent {
  @Output() isGeoFenceEnabled = new EventEmitter<boolean>();

  activeGroup = 'allEnabled'; // Default group to be active

  // Tab groups
  tabGroups: { label: string; icon: string; value: string }[] = [
    {
      label: 'Geo Fence',
      icon: 'fa-thin fa-location-dot',
      value: 'geoFenceEnabled',
    },
  ];

  // Switch Fence Visibility
  switchFence(event: { originalEvent: Event; checked: boolean }) {
    this.isGeoFenceEnabled.emit(event.checked);
  }

  // Function to activate groups
  activateGroup(group: string) {
    switch (group) {
      case 'geoFenceEnabled':
        this.activeGroup = 'geoFenceEnabled';
        break;
      default:
        this.activeGroup = 'allEnabled';
        break;
    }
  }
}
