<div mat-dialog-content>
  <div class="passcode-section">
    <code-input
      #codeInput
      [isCodeHidden]="true"
      [codeLength]="4"
      ngClass="code-input-height"
      (codeCompleted)="onCodeCompleted($event)">
    </code-input>
  </div>
</div>

<p-toast position="center" [life]="500" key="br"></p-toast>
