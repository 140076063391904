import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CarouselModule } from 'primeng/carousel';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CardModule } from 'primeng/card';
import { TreeModule } from 'primeng/tree';
import { ChipsModule } from 'primeng/chips';
import { SkeletonModule } from 'primeng/skeleton';
import { GalleriaModule } from 'primeng/galleria';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { PaginatorModule } from 'primeng/paginator';
import { TreeSelectModule } from 'primeng/treeselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ListboxModule } from 'primeng/listbox';
import { ChipModule } from 'primeng/chip';

const modules = [
  ButtonModule,
  CarouselModule,
  InputTextModule,
  InputNumberModule,
  PasswordModule,
  DividerModule,
  TabMenuModule,
  ToggleButtonModule,
  OverlayPanelModule,
  CheckboxModule,
  BadgeModule,
  TooltipModule,
  DropdownModule,
  CalendarModule,
  DialogModule,
  ToastModule,
  DynamicDialogModule,
  MenuModule,
  AccordionModule,
  TabViewModule,
  ConfirmDialogModule,
  SelectButtonModule,
  MultiSelectModule,
  RadioButtonModule,
  ConfirmPopupModule,
  CardModule,
  TreeModule,
  ChipsModule,
  SkeletonModule,
  GalleriaModule,
  InputSwitchModule,
  StepsModule,
  PaginatorModule,
  TreeSelectModule,
  ProgressSpinnerModule,
  ListboxModule,
  ChipModule,
];

@NgModule({
  imports: [...modules],
  exports: [...modules],
  providers: [MessageService, DialogService, ConfirmationService],
})
export class PrimeNgModule {}
