import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { IChartData } from '../../interfaces';

@Component({
  selector: 'app-chart-area',
  templateUrl: './chart-area.component.html',
  styleUrls: ['./chart-area.component.scss'],
})
export class ChartAreaComponent {
  _plottingData: [[string, number]] = [['', 0]];
  Highcharts: typeof Highcharts = Highcharts;
  isAssetListOpen = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartRef: any;

  @Input({ required: true })
  set plottingData(data: IChartData) {
    if (data) {
      Object.entries(data).forEach(([key, value]) =>
        this._plottingData.push([key, +value])
      );
    }

    if (this.chartOptions.series && this.chartOptions.series[0]) {
      (this.chartOptions.series[0] as Highcharts.SeriesAreaOptions).data =
        this._plottingData;
    }
  }
  get plottingData(): [[string, number]] {
    return this._plottingData;
  }
  get chart_width(): string {
    return this.isAssetListOpen ? 'open' : 'closed';
  }
  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: '#fafbfd',
      showAxes: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: '{point.y}',
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    yAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    plotOptions: {
      area: {
        pointStart: 0,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#D94524'],
            [1, '#F88580'],
          ],
        },
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        data: [],
        type: 'area',
        dashStyle: 'ShortDash',
      },
    ],
  };

  chartCallback: Highcharts.ChartCallbackFunction = chart => {
    this.chartRef = chart;
  };

  onReflow() {
    this.isAssetListOpen = !this.isAssetListOpen;
    console.log('Reflow');
    this.chartRef.reflow();
  }
}
