<div class="date-range-selector">
  <span>Time range:</span>
  <div
    class="ranges-buttons"
    *ngIf="!customDateSelect; else customDateSelector">
    <ng-container *ngFor="let range of dateRanges">
      <p-button
        label="{{ range }}D"
        [text]="true"
        [raised]="true"
        [plain]="true"
        size="small"
        [ngClass]="{
          'button-selection': range === selectedRange
        }"
        (onClick)="rangeSelected(range)" />
    </ng-container>
  </div>
  <ng-template #customDateSelector>
    <div class="custom-date-select">
      <p-calendar
        [(ngModel)]="rangeDates"
        (ngModelChange)="onCustomRangeSelected($event)"
        selectionMode="range"
        placeholder="Custom date range"
        (onClose)="onRangeSelectClosed()"
        [readonlyInput]="true"
        [style]="{ width: '100%' }"
        [numberOfMonths]="2"
        dateFormat="M dd, yy" />
    </div>
  </ng-template>
  <span
    class="pi"
    [ngClass]="!customDateSelect ? 'pi-calendar' : 'pi-calendar-clock'"
    style="font-size: 2rem"
    (click)="customDateSelect = !customDateSelect"></span>
</div>
