<div class="toolbar-container" [ngSwitch]="activeGroup">
  <ng-container *ngSwitchCase="'allEnabled'">
    <div class="group_container" *ngFor="let tab of tabGroups">
      <p-button
        [text]="true"
        (click)="activateGroup(tab.value)"
        [pTooltip]="tab.label"
        tooltipPosition="bottom"
        [raised]="false"
        class="sub-menu-btn">
        <span class="inside_button">
          <i [class]="tab.icon"></i>
          <span>{{ tab.label }}</span>
        </span>
      </p-button>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'geoFenceEnabled'">
    <p-button
      icon="fa-light fa-arrow-left"
      [rounded]="false"
      [text]="true"
      [outlined]="true"
      (click)="activateGroup('allEnabled')"></p-button>
    <div id="right-end" class="geofence_actions">
      <div class="geo_radio">
        <p>Geo Location:</p>
        <div class="card flex justify-content-center">
          <p-inputSwitch (onChange)="switchFence($event)"></p-inputSwitch>
        </div>
      </div>
    </div>
  </ng-container>
</div>
