import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.development';
import {
  ConsumableData,
  InStockData,
  IServiceRemindersResponse,
  REMINDER_STATUS,
} from '../interfaces';
import {
  IAllModulesResponse,
  ISubModulePreferenceRequest,
  ISubModuleResponse,
} from 'src/app/shared/interfaces';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  http = inject(HttpClient);
  apiError = inject(ApiErrorService);

  getServiceReminders(
    pageNumber: number,
    pageSize: number,
    statuses: Array<REMINDER_STATUS>
  ): Observable<IServiceRemindersResponse> {
    return this.http
      .post<IServiceRemindersResponse>(
        `${environment.baseUrl}/service-reminder`,
        {
          pageNumber,
          pageSize,
          statuses,
        }
      )
      .pipe(catchError(this.apiError.handleError));
  }

  getMaintenanceModules(): Observable<ISubModuleResponse[]> {
    return this.http
      .get<{
        sidebar: IAllModulesResponse[];
      }>(`${environment.baseUrl}/auth/modules`)
      .pipe(
        map(data => data.sidebar),
        map(item =>
          item.filter(modules => modules.moduleName === 'Maintenance')
        ),
        switchMap(module => {
          return this.http
            .get<{
              button: ISubModuleResponse[];
            }>(`${environment.baseUrl}/auth/modules/${module[0].moduleId}`)
            .pipe(map(data => data.button));
        }),
        catchError(this.apiError.handleError)
      );
  }

  updateSubModulePreference(
    modules: ISubModulePreferenceRequest[]
  ): Observable<{ [status: string]: string }> {
    return this.http
      .get<{
        sidebar: IAllModulesResponse[];
      }>(`${environment.baseUrl}/auth/modules`)
      .pipe(
        map(data => data.sidebar),
        map(item =>
          item.filter(modules => modules.moduleName === 'Maintenance')
        ),
        switchMap(module => {
          return this.http
            .patch<{
              [status: string]: string;
            }>(
              `${environment.baseUrl}/auth/modules/${module[0].moduleId}/update-submodule-preference`,
              modules
            )
            .pipe(map(data => data));
        }),
        catchError(this.apiError.handleError)
      );
  }

  getInStockReport(
    type: string,
    siteIds: number[] = []
  ): Observable<InStockData[]> {
    const initialParam = this.getSiteIdsHttpParams(siteIds);
    const params = initialParam.append('type', type);
    return this.http
      .get<InStockData[]>(`${environment.baseUrl}/stock/in-stock-report`, {
        params: params,
      })
      .pipe(catchError(this.apiError.handleError));
  }

  getConsumableInStockById(consumableId: number): Observable<ConsumableData> {
    return this.http
      .get<ConsumableData>(
        `${environment.baseUrl}/stock/consumable/${consumableId}`
      )
      .pipe(catchError(this.apiError.handleError));
  }

  updateConsumableData(data: ConsumableData): Observable<ConsumableData> {
    return this.http.post<ConsumableData>(
      `${environment.baseUrl}/stock/add-consumable-details`,
      data
    );
  }

  deleteConsumableById(
    consumableId: number
  ): Observable<{ name: string; disabled: boolean }> {
    return this.http.patch<{ name: string; disabled: boolean }>(
      `${environment.dataRestUrl}/consumables/${consumableId}`,
      { disabled: true }
    );
  }

  deleteStockItemById(
    itemId: number
  ): Observable<{ name: string; disabled: boolean }> {
    return this.http.patch<{ name: string; disabled: boolean }>(
      `${environment.dataRestUrl}/stockItems/${itemId}`,
      { disabled: true }
    );
  }

  getSiteIdsHttpParams(siteIds?: number[]): HttpParams {
    const param = new HttpParams();
    if (siteIds === undefined || siteIds.length === 0) {
      return param;
    } else {
      return param.set('siteIds', siteIds.toString());
    }
  }
}
