import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HighchartsChartModule } from 'highcharts-angular';
import { CodeInputModule } from 'angular-code-input';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';

import { PrimeNgModule } from './primeng.module';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

import { HeaderComponent } from './header/header.component';
import { ActionsComponent } from './actions/actions.component';
import { ChartCardComponent } from './chart-card/chart-card.component';
import { LabelValueCardComponent } from './templates/label-value-card/label-value-card.component';
import { SelectableCardComponent } from './selectable-card/selectable-card.component';
import { TruncateTextPipe } from '../core/pipes/truncate-text.pipe';
import { TitleCasePipe } from '../core/pipes/title-text.pipe';
import { TimePeriodSelectComponent } from './time-period-select/time-period-select.component';
import { PasscodeComponent } from './passcode/passcode.component';
import { AsideEmptyDataComponent } from './aside/empty-data/empty-data.component';
import { AsideLoadingDataComponent } from './aside/loading-data/loading-data.component';
import { AsideHeaderComponent } from './aside/header/aside-header.component';
import { DropdownCellEditorComponent } from './ag-grid-renderers/dropdown-cell-editor/dropdown-cell-editor.component';
import { CrudComponent } from './ag-grid-renderers/crud/crud.component';
import { ChartAreaComponent } from './charts/chart-area/chart-area.component';
import { ChartComponent } from './charts/chart/chart.component';
import { ChartBarComponent } from './charts/chart-bar/chart-bar.component';
import { ChartColumnComponent } from './charts/chart-column/chart-column.component';
import { ChartPieComponent } from './charts/chart-pie/chart-pie.component';
import { ChartGanttComponent } from './charts/chart-gantt/chart-gantt.component';
import { ChartLineComponent } from './charts/chart-line/chart-line.component';
import { MonthSelectComponent } from './month-select/month-select.component';

@NgModule({
  declarations: [
    ErrorDialogComponent,
    HeaderComponent,
    ActionsComponent,
    CrudComponent,
    ChartCardComponent,
    LabelValueCardComponent,
    SelectableCardComponent,
    TruncateTextPipe,
    TitleCasePipe,
    TimePeriodSelectComponent,
    PasscodeComponent,
    AsideEmptyDataComponent,
    AsideLoadingDataComponent,
    AsideHeaderComponent,
    DropdownCellEditorComponent,
    ChartAreaComponent,
    ChartComponent,
    ChartBarComponent,
    ChartColumnComponent,
    ChartPieComponent,
    ChartGanttComponent,
    ChartLineComponent,
    MonthSelectComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PrimeNgModule,
    AgGridModule,
    HighchartsChartModule,
    BreadcrumbModule,
    CodeInputModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    ActionsComponent,
    PrimeNgModule,
    AgGridModule,
    ChartCardComponent,
    LabelValueCardComponent,
    SelectableCardComponent,
    TruncateTextPipe,
    TitleCasePipe,
    TimePeriodSelectComponent,
    AsideEmptyDataComponent,
    AsideLoadingDataComponent,
    AsideHeaderComponent,
    DropdownCellEditorComponent,
    MonthSelectComponent,
    ChartComponent,
  ],
  providers: [
    BreadcrumbService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    DecimalPipe,
  ],
})
export class SharedModule {}
