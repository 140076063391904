import { createAction, props } from '@ngrx/store';
import {
  ISubModulePreferenceRequest,
  ISubModuleResponse,
} from 'src/app/shared/interfaces';
import {
  TripCountResponse,
  TripSpecificationRequest,
  TripSpecificationResponse,
  TripCountRequestObject,
  TripPathResponse,
  saveTripObject,
} from '../interfaces';

const SET_PRODUCTION_ASIDE_VISIBILITY =
  '[Production] Set Production Aside Visibility';

const GET_PRODUCTION_MODULES_START =
  '[Production] Get Production Modules Start';

const GET_PRODUCTION_MODULES_SUCCESS =
  '[Production] Get Production Modules Success';
const GET_PRODUCTION_MODULES_FAILURE =
  '[Production] Get Production Modules Failure';

const SET_PRODUCTION_MODULE_PREFERENCE =
  '[Production] Set Production Module Preference';
const SET_PRODUCTION_MODULE_PREFERENCE_SUCCESS =
  '[Production] Set Production Module Preference Success';
const SET_PRODUCTION_MODULE_PREFERENCE_FAILURE =
  '[Production] Set Production Module Preference Failure';

const SET_PRODUCTION_ACTIVE_ACTION = '[Production] Set Active Action';

const RESET_PRODUCTION_MESSAGE_STATUS = '[Production] Reset message status';

const GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT =
  '[Production] Get Production Modules Trip Report';
const GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT_SUCCESS =
  '[Production] Get Production Modules Trip Report Success';
const GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT_FAILURE =
  '[Production] Get Production Modules Trip Report Failure';

const GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST =
  '[Production] Get Production Modules Trip Specification List';
const GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST_SUCCESS =
  '[Production] Get Production Modules Trip Specification List Success';
const GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST_FAILURE =
  '[Production] Get Production Modules Trip Specification List Failure';

const CLEAR_PRODUCTION_STORE = '[Production] Clear Production Store';

const GET_PRODUCTION_MODULES_TRIP_PATH =
  '[Production] Get Production Modules Trip Path List';
const GET_PRODUCTION_MODULES_TRIP_PATH_LIST_SUCCESS =
  '[Production] Get Production Modules Trip Path List Success';
const GET_PRODUCTION_MODULES_TRIP_PATH_LIST_FAILURE =
  '[Production] Get Production Modules Trip Path List Failure';

const SET_PRODUCTION_MODULES_TRIP_COUNT =
  '[Production] Set Production Trip Count';
const SET_PRODUCTION_MODULES_TRIP_COUNT_SUCCESS =
  '[Production] Set Production Trip Count Success';
const SET_PRODUCTION_MODULES_TRIP_COUNT_FAILURE =
  '[Production] Set Production Trip Count Failure';

export const setProductionAside = createAction(
  SET_PRODUCTION_ASIDE_VISIBILITY,
  props<{ isAsideVisible: boolean }>()
);

export const getProductionModules = createAction(GET_PRODUCTION_MODULES_START);

export const getProductionModulesSuccess = createAction(
  GET_PRODUCTION_MODULES_SUCCESS,
  props<{ modules: ISubModuleResponse[] }>()
);

export const getProductionModuleFailure = createAction(
  GET_PRODUCTION_MODULES_FAILURE,
  props<{ error: string }>()
);

export const setProductionModulePreference = createAction(
  SET_PRODUCTION_MODULE_PREFERENCE,
  props<{ subModules: ISubModulePreferenceRequest[] }>()
);

export const setProductionModulePreferenceSuccess = createAction(
  SET_PRODUCTION_MODULE_PREFERENCE_SUCCESS,
  props<{ status: string; subModules: ISubModulePreferenceRequest[] }>()
);

export const setProductionModulePreferenceFailure = createAction(
  SET_PRODUCTION_MODULE_PREFERENCE_FAILURE,
  props<{ error: string }>()
);

export const setProductionActiveAction = createAction(
  SET_PRODUCTION_ACTIVE_ACTION,
  props<{ moduleId: number | null }>()
);

export const resetMessageStatus = createAction(RESET_PRODUCTION_MESSAGE_STATUS);

export const getProductionTripCountReport = createAction(
  GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT,
  props<{ req: TripCountRequestObject }>()
);

export const getProductionTripCountReportSuccess = createAction(
  GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT_SUCCESS,
  props<{ tripContResponse: TripCountResponse[] }>()
);

export const getProductionTripCountReportFailure = createAction(
  GET_PRODUCTION_MODULES_TRIP_COUNT_REPORT_FAILURE,
  props<{ error: string }>()
);

export const getProductionTripSpecification = createAction(
  GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST,
  props<{ specificationReq: TripSpecificationRequest }>()
);

export const getProductionTripSpecificationSuccess = createAction(
  GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST_SUCCESS,
  props<{ specificationRes: TripSpecificationResponse[] }>()
);

export const getProductionTripSpecificationSuccessFailure = createAction(
  GET_PRODUCTION_MODULES_TRIP_SPECIFICATION_LIST_FAILURE,
  props<{ error: string }>()
);

export const getProductionModulesPathList = createAction(
  GET_PRODUCTION_MODULES_TRIP_PATH
);

export const getProductionModulesPathListSuccss = createAction(
  GET_PRODUCTION_MODULES_TRIP_PATH_LIST_SUCCESS,
  props<{ data: TripPathResponse[] }>()
);

export const getProductionModulesPathListFailure = createAction(
  GET_PRODUCTION_MODULES_TRIP_PATH_LIST_FAILURE,
  props<{ error: string }>()
);

export const saveTripCount = createAction(
  SET_PRODUCTION_MODULES_TRIP_COUNT,
  props<{
    saveTripReqObj: saveTripObject[];
  }>()
);

export const saveTripCountSuccess = createAction(
  SET_PRODUCTION_MODULES_TRIP_COUNT_SUCCESS,
  props<{
    saveTripReqObj: saveTripObject[];
  }>()
);

export const saveTripCountFailure = createAction(
  SET_PRODUCTION_MODULES_TRIP_COUNT_FAILURE,
  props<{ error: string }>()
);

export const clearProductionStore = createAction(CLEAR_PRODUCTION_STORE);
