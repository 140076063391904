/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Component, Input, inject, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import * as fromAuthStore from '../../auth/store';
import * as fromAssetStore from '../../asset/store';
import * as fromFuelStore from '../../fuel/store';
import * as fromMaintenanceStore from '../../maintenance/store';
import * as fromProductionStore from '../../production/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  actionItems: MenuItem[];

  store = inject(Store);
  @Input({ required: true }) title: string;
  @Input({ required: true }) icon: string;
  userMenuItems: MenuItem[];
  userName: string | undefined;
  destroy$ = new Subject<void>();

  ngOnInit() {
    this.store
      .select(fromAuthStore.selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => (this.userName = user?.userName));

    this.actionItems = [
      { label: 'Report', icon: 'fa-light fa-file-chart-pie' },
      { label: 'Task', icon: 'fa-light fa-server' },
      { label: 'Logbook', icon: 'fa-light fa-notebook' },
    ];

    this.userMenuItems = [
      {
        label: this.userName,
        disabled: true,
        icon: 'fa-regular fa-user',
      },
      {
        label: 'Logout',
        icon: 'fa-regular fa-right-from-bracket',
        command: () => this.initiateLogout(),
      },
    ];
  }

  initiateLogout(): void {
    this.store.dispatch(fromAuthStore.logout());
    this.store.dispatch(fromAssetStore.clearAssetStore());
    this.store.dispatch(fromFuelStore.clearFuelStore());
    this.store.dispatch(fromMaintenanceStore.clearMaintenanceStore());
    this.store.dispatch(fromProductionStore.clearProductionStore());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
