import { IShift } from 'src/app/configuration/interfaces/configuration.interfaces';

export type ProductionActionViewTypes = 'home' | 'map' | 'side';

export interface TripSpecifications {
  id: number;
  name: string;
  category: string;
  bulkDensity: number;
  minimumQuota: number;
  productionMultiplicity: number;
  targetCountEnabled: boolean;
  fillingFactorEnabled: boolean;
  siteId: number;
}

export interface ProductionNavigation {
  name: string;
  value: string;
  isVisible: boolean;
  iconClass: string;
}

export enum ProductionNavigationEnum {
  Mining = 'MINING',
  PlantLoad = 'PLANT_LOAD',
  Reclaim = 'RECLAIM',
}

export interface TripCountResponse {
  assetId: number;
  assetName: string;
  assetClassName: string;
  siteName: string;
  production: number;
  tripCount: number;
  sourceGeoFence: string;
  destinationGeoFence: string;
  specification: string;
  shiftNumber: number;
  operatorName: string;
  timestamp: string;
  category: string;
  shiftTrips: IShift[];
  targetCount: number;
}

export interface TripCountRequestObject {
  fromDate: string;
  toDate: string;
  category: string;
  siteIds: number[];
}

export interface TripSpecificationRequest {
  category: string;
}

export interface TripSpecificationResponse {
  id: number;
  name: string;
  category: string;
  bulkDensity: number;
  minimumQuota: number;
  productionMultiplicity: number;
  targetCountEnabled: boolean;
  fillingFactorEnabled: boolean;
  siteId: number;
}

export interface SpecificationList {
  id: number;
  name: string;
  type: string;
  quantity: number;
  secondQuantity: number;
  siteId: number;
}

export interface TripPathResponse {
  id: number;
  sourceGeofenceId: number;
  sourceGeofenceName: string;
  destinationGeofenceId: number;
  destinationGeofenceName: string;
  siteId: number;
  defaultSpecificationId: null;
}

export interface saveTripObject {
  assetId: number;
  destinationGeofenceId: number;
  fillingFactor: number;
  operatorName: string;
  pathId?: number;
  sourceGeofenceId: number;
  specificationId: number;
  targetCount: number;
  timestamp: string;
  tripCount: number;
}
