import { Component } from '@angular/core';

@Component({
  selector: 'app-aside-empty-data',
  template: `
    <div class="empty-content">
      <img src="../../../assets/icons/no_data_small.svg" alt="No data" />
      <p class="empty-heading">No Data</p>
      <p class="empty-sub-heading">There is no data to show you right now</p>
    </div>
  `,
  styles: [
    `
      .empty-content {
        text-align: center;
        margin-top: 40%;
        font-weight: 500;
      }

      .empty-heading {
        font-size: 1.9rem;
        color: #939090;
        margin: 1rem 0 0;
      }

      .empty-sub-heading {
        font-size: 1.3rem;
        font-weight: 400;
        color: #a0a0a0;
        margin: 0;
      }
    `,
  ],
})
export class AsideEmptyDataComponent {}
