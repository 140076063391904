<div class="sidebar">
  <div class="branding sidebar-logo">
    <img src="assets/cog_sidebar_logo.svg" alt="Cognecto Logo" />
  </div>
  <div class="sidebar-icons">
    <button
      *ngFor="let item of items"
      pButton
      [routerLink]="item.routerLink"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      icon="{{ item.icon }}"
      (click)="currentSideNavModule = item.routerLink"
      class="p-button-text {{ item.styleClass }} {{
        currentSideNavModule === item.routerLink.split('/')[0]
          ? 'active-module'
          : ''
      }}"
      [pTooltip]="item.label"></button>
  </div>
</div>
