import { Component, ViewChild } from '@angular/core';
import { CodeInputComponent } from 'angular-code-input';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PasscodeService } from '../services/passcode.service';

@Component({
  selector: 'app-passcode',
  templateUrl: './passcode.component.html',
  styleUrls: ['./passcode.component.scss'],
})
export class PasscodeComponent {
  @ViewChild('codeInput', { static: true }) codeInput: CodeInputComponent;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private passcodeService: PasscodeService
  ) {}

  onCodeCompleted(event: string) {
    const data = {
      subModuleId: this.config.data.subModuleId,
      passcode: Number(event),
    };
    this.passcodeService.verifyPasscode(data).subscribe({
      next: res => {
        if (res !== null) {
          this.ref.close(res);
        }
      },
      error: () => {
        this.codeInput.reset();
      },
    });
  }
}
