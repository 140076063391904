<div class="flex flex-row">
  <app-side-nav *ngIf="isSideNavVisible$ | async"></app-side-nav>
  <main class="sidenav-content">
    <router-outlet></router-outlet>
  </main>
</div>

<p-toast
  position="bottom-center"
  key="bc-toast"
  [preventOpenDuplicates]="true"
  [life]="2500"></p-toast>
