<div class="container">
  <div class="container__left">
    <div class="container__title">View :</div>
    <div class="container__toggle-btn-menu">
      <p-toggleButton
        class="tgl-btn"
        *ngIf="availableViews[0]"
        [onIcon]="'fa-regular fa-map'"
        offIcon="fa-regular fa-map"
        (click)="onViewChange(availableViews[0])">
      </p-toggleButton>
      <p-toggleButton
        class="tgl-btn"
        *ngIf="availableViews[1]"
        [onIcon]="'fa-regular fa-signal-stream'"
        offIcon="fa-regular fa-signal-stream"
        (click)="onViewChange(availableViews[1])">
      </p-toggleButton>
      <p-toggleButton
        class="tgl-btn"
        *ngIf="availableViews[2]"
        [onIcon]="'fas fa-brackets'"
        offIcon="fas fa-brackets"
        (click)="onViewChange(availableViews[2])">
      </p-toggleButton>
    </div>
  </div>
  <div class="container__right">
    <div class="container__sub-menu-bar">
      <ng-container *ngFor="let button of modules">
        <div class="container__sub-menu-div" *ngIf="button.preferred">
          <p-button
            class="sub-menu-btn"
            (click)="onActionNavigate(button.subModuleParamId)"
            [ngClass]="{
              'button-selection': button.subModuleParamId === selectedSubModule
            }"
            [pTooltip]="button.subModuleParamName"
            tooltipPosition="bottom">
            <i [class]="button.icon"></i>
            <span class="button-text">{{ button.subModuleParamName }}</span>
          </p-button>
        </div>
      </ng-container>
    </div>
    <div class="container__add-btn" *ngIf="showAddButton">
      <p-overlayPanel #op styleClass="app-action-overlay-panel">
        <ng-container *ngFor="let button of modules">
          <div
            class="checkbox-container"
            [ngClass]="{
              'button-selection': button.subModuleParamId === selectedSubModule
            }">
            <p-checkbox
              class="checkbox-item"
              [binary]="true"
              [ngModel]="button.preferred"
              (onChange)="onActionSelectionChange(button)">
            </p-checkbox>
            <label
              class="checkbox-label"
              (click)="onActionNavigate(button.subModuleParamId)">
              <i [class]="'checkbox-label-icon pi ' + button.icon"></i>
              {{ button.subModuleParamName }}
            </label>
          </div>
          <p-divider></p-divider>
        </ng-container>
      </p-overlayPanel>
      <p-button
        class="add-btn"
        (click)="op.toggle($event)"
        icon="pi pi-plus"></p-button>
    </div>
    <p-divider class="action-bar-divider" layout="vertical"></p-divider>
    <div class="container__action-menu">
      <p-dropdown
        [options]="pageViewOptions"
        optionLabel="label"
        (onChange)="onPageViewChange($event)"
        styleClass="dropdown-width">
        <ng-template pTemplate="selectedItem" let-selectedItem>
          <div class="dropdown__selected-item">
            <img
              class="dropdown__item-image"
              [src]="'../../../assets/icons/' + selectedItem.image + '.svg'" />
            <span class="dropdown__item-label">{{ selectedItem.label }}</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="dropdown__item">
            <img
              class="dropdown__item-image"
              [src]="'../../../assets/icons/' + item.image + '.svg'" />
            <span class="dropdown__item-label">{{ item.label }}</span>
          </div>
        </ng-template>
      </p-dropdown>
      <p-button class="action-menu-btn">
        <i class="fa-regular fa-square-dashed-circle-plus"></i>
        <span>Activity</span>
      </p-button>
    </div>
  </div>
</div>
