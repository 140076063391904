<div
  class="container-open"
  [ngStyle]="{
    display: isAsideVisible ? 'block' : 'none'
  }">
  <div class="aside-icon">
    <p-button
      icon="fa-regular fa-chevron-left"
      [rounded]="true"
      severity="secondary"
      [outlined]="true"
      [raised]="true"
      (click)="toggleAside()" />
  </div>
  <p-tree
    [value]="assetsList"
    [filter]="true"
    [(selection)]="selectedNodes"
    filterPlaceholder="Search"
    [selectionMode]="selectedMode"
    [metaKeySelection]="false"
    [scrollHeight]="'calc(100vh - 220px)'"
    (selectionChange)="onSelectionChange($event)">
    <ng-template let-node pTemplate="site">
      <div class="site">
        <i class="fa-regular fa-circle-o icon"></i>
        <span class="site__label">
          {{ node.label }}
        </span>
      </div>
    </ng-template>
    <ng-template let-node pTemplate="assetClass">
      <div class="assetClass">
        <i class="fa-regular fa-cube icon"></i>
        <span class="assetClass__label">
          {{ node.label }}
        </span>
      </div>
    </ng-template>
    <ng-template let-asset pTemplate="asset">
      <div class="asset">
        <i class="fa-regular fa-rectangles-mixed icon"></i>
        <span class="asset__label">
          {{ asset.label }}
        </span>
        <span class="spacer"></span>
        <i
          class="fa-solid fa-circle asset__status"
          [ngStyle]="
            asset.data.status === 'INACTIVE'
              ? { color: '#FF2F40' }
              : { color: '#00C577' }
          "></i>
      </div>
    </ng-template>
    <ng-template pTemplate="togglericon" let-open>
      <span *ngIf="open" class="pi pi-minus"></span>
      <span *ngIf="!open" class="pi pi-plus"></span>
    </ng-template>
    <ng-template pTemplate="empty">
      <div class="empty">
        <i class="fa-solid fa-box-open icon"></i>
        <span class="empty__label">No Assets Found</span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <span class="header">Asset List</span>
    </ng-template>
  </p-tree>
</div>

<div
  class="container-closed"
  [ngStyle]="{
    display: !isAsideVisible ? 'block' : 'none'
  }">
  <div class="aside-icon">
    <p-button
      icon="fa-regular fa-chevron-right"
      [rounded]="true"
      severity="secondary"
      [outlined]="true"
      [raised]="true"
      (click)="toggleAside()" />
  </div>
</div>
