import { ProductionNavigationEnum } from './interfaces';

export const SuccessMessage = {
  SAVE_TRIP_COUNT: 'Trip Count Saved successfully.',
};

export const Category = {
  MINING: 'Mining',
  PLANT_LOAD: 'Plant Load',
  RECLAIM: 'Reclaim',
};

export const NavigationButtonInfo = [
  {
    name: 'Mining',
    value: ProductionNavigationEnum.Mining,
    isVisible: true,
    iconClass: 'fa-truck',
  },
  {
    name: 'Plant Load',
    value: ProductionNavigationEnum.PlantLoad,
    isVisible: true,
    iconClass: 'fa-bulldozer',
  },
  {
    name: 'Reclaim',
    value: ProductionNavigationEnum.Reclaim,
    isVisible: true,
    iconClass: 'fa-recycle',
  },
];
