import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-chart-gantt',
  templateUrl: './chart-gantt.component.html',
  styleUrls: ['./chart-gantt.component.scss'],
})
export class ChartGanttComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: '#fafbfd',
      showAxes: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    yAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    series: [
      {
        type: 'gantt',
        data: [],
      },
    ],
  };
}
