import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from './environments/environment';

LicenseManager.setLicenseKey(environment.aggrid.license);
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
