<div
  class="card-container"
  [ngClass]="
    card.isSelected ? 'card-container--highlighted' : 'card-container--normal'
  ">
  <div class="card-container__header">
    <h3 [pTooltip]="card.header">
      {{ card.header | truncateText }}
    </h3>
    <a
      *ngIf="card.showDeleteBtn && card.isSelected"
      (click)="cardDeleteEvent.emit()">
      <i class="fa-duotone fa-solid fa-trash card-container__delete-icon"></i>
    </a>
  </div>
  <div class="card-container__details">
    <div
      class="card-container__column card-container__column--{{
        card.columns.length
      }}"
      [ngClass]="column?.styleClass"
      *ngFor="let column of card.columns">
      <span> {{ column?.label }}</span>
      <p
        class="card-container__paragraph"
        [pTooltip]="column.value"
        [ngStyle]="{ color: column?.color }">
        {{ column.value | truncateText: 12 }}
      </p>
    </div>
  </div>
</div>
