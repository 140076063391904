import { Component } from '@angular/core';
import { ICellEditorComp } from 'ag-grid-community';
import { DropdownOption } from '../../interfaces/shared.interfaces';

@Component({
  selector: 'app-dropdown-cell-editor',
  templateUrl: './dropdown-cell-editor.component.html',
  styleUrls: ['./dropdown-cell-editor.component.scss'],
})
export class DropdownCellEditorComponent implements ICellEditorComp {
  options: DropdownOption[] = [];
  selectedOption: DropdownOption | null = null;
  disabled = false;

  agInit(params: any): void {
    this.options = params.values;
    const selectedOption = this.options.find(
      (opetion: DropdownOption) => opetion.name === params.value
    );
    this.selectedOption = selectedOption || null;
  }

  getValue() {
    return this.selectedOption;
  }

  isPopup(): boolean {
    return false;
  }

  getGui(): HTMLElement {
    const dropdownElement = document.createElement('div');
    dropdownElement.innerHTML = '<p-dropdown></p-dropdown>';
    return dropdownElement;
  }
}
