import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss'],
})
export class ChartPieComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: '#fafbfd',
      showAxes: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      lineWidth: 0,
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    yAxis: {
      gridLineColor: '#f1f1f1',
      gridLineWidth: 1,
      title: {
        text: '',
      },
      labels: {
        style: {
          fontSize: '1rem',
          color: '#7d7d7d',
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: 'pie',
        innerSize: '75%',
        data: [1, 2, 3, 4, 5, 56],
        colors: [
          '#4572A7',
          '#AA4643',
          '#89A54E',
          '#80699B',
          '#3D96AE',
          '#DB843D',
          '#92A8CD',
          '#A47D7C',
          '#B5CA92',
        ],
      },
    ],
  };
}
