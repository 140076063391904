export const RefuelTankTypes = {
  MAIN_TANK: 'Refuel Main Tank',
  BOWSER: 'Refuel Bowser',
  BOOKING: 'Plant Booking',
};

export const SuccessMessage = {
  FUEL_SOURCE_UPDATED: 'Fuel source updated successfully.',
  REFUEL_RECORD_SAVED: 'Refuel record saved successfully',
};

export const ErrorMessage = {
  INVALID_DATA: 'Invalid data entered!!!',
  INVALID_TANK_METER_READING: 'Invalid tank meter readings entered!!!',
  NO_CONTENT: 'No content entered!!!',
};

export const pInputStyle = {
  width: '18vw',
  height: '3vw',
  display: 'flex',
  'align-items': 'center',
};

export const supplierTank = {
  id: 1,
  name: 'Supplier Tank',
};

export const DEFAULT_SUB_MODULE_SELECTION = null;

export const startOfDayTime = { hour: 0, minute: 0, second: 0, millisecond: 0 };
