import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReducer from './auth.reducer';

export const AUTH_STATE_NAME = 'auth';

export const selectAuthState =
  createFeatureSelector<fromReducer.AuthState>(AUTH_STATE_NAME);

export const selectIsAuthenticated = createSelector(selectAuthState, state =>
  state.user ? true : false
);

export const selectUser = createSelector(selectAuthState, state =>
  state.user ? state.user : null
);
