import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardData } from '../interfaces';

@Component({
  selector: 'app-selectable-card',
  templateUrl: 'selectable-card.component.html',
  styleUrls: ['selectable-card.component.scss'],
})
export class SelectableCardComponent {
  @Input({ required: true }) card: CardData;
  @Output() cardDeleteEvent = new EventEmitter<null>();
}
