import { createReducer, Action } from '@ngrx/store';

import { IMessageStatus, ISubModuleResponse } from 'src/app/shared/interfaces';

export interface FleetState {
  globalSelectedSubModule: number | null;
  modules: Array<ISubModuleResponse>;
  messageStatus: IMessageStatus | null;
}

const initialState: FleetState = {
  globalSelectedSubModule: null,
  modules: [],
  messageStatus: null,
};
export const _fleetReducer = createReducer(initialState);

export function fleetReducer(state: FleetState | undefined, action: Action) {
  return _fleetReducer(state, action);
}
