import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-aside-header',
  template: `
    <div class="aside-header">
      <span class="p-input-icon-left aside-input-container">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          [placeholder]="placeHolder"
          [(ngModel)]="dataToBeFiltered"
          (ngModelChange)="filterAsideData.emit(dataToBeFiltered)" />
      </span>
      <p-button
        label="New"
        icon="fa-light fa-square-plus"
        severity="accent"
        (click)="createNewData.emit()"></p-button>
    </div>
  `,
  styleUrls: ['./aside-header.component.scss'],
})
export class AsideHeaderComponent {
  @Input({ required: true }) placeHolder: string;
  @Output() createNewData = new EventEmitter<void>();
  @Output() filterAsideData = new EventEmitter<string>();

  dataToBeFiltered = '';
}
