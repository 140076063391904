<div class="container">
  <ng-container *ngTemplateOutlet="isLoading ? skeleton : chart"></ng-container>
</div>
<ng-template #chart>
  <div class="info">
    <div class="title">Tons Per Litre</div>
  </div>
  <div class="chart">
    <app-chart-line [plottingData]="chartData"></app-chart-line>
  </div>
</ng-template>

<ng-template #skeleton>
  <div class="border-round border-1 surface-border p-4 surface-card skeleton">
    <div class="flex mb-3">
      <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
      <div>
        <p-skeleton width="10rem" styleClass="mb-2" />
        <p-skeleton width="5rem" styleClass="mb-2" />
        <p-skeleton height=".5rem" />
      </div>
    </div>
    <p-skeleton width="100%" height="250px" />
    <div class="flex justify-content-between mt-3">
      <p-skeleton width="4rem" height="2rem" />
      <p-skeleton width="4rem" height="2rem" />
    </div>
  </div>
</ng-template>
