import { createReducer, on, Action } from '@ngrx/store';

import * as fromProductionActions from './production.action';
import {
  TripCountResponse,
  TripSpecificationResponse,
  TripPathResponse,
  saveTripObject,
} from '../interfaces';
import { SuccessMessage } from '../constants';
import { IMessageStatus, ISubModuleResponse } from 'src/app/shared/interfaces';

export interface ProductionState {
  isAsideVisible: boolean;
  globalSelectedSubModule: number | null;
  modules: Array<ISubModuleResponse>;
  messageStatus: IMessageStatus | null;
  tripCountReport: Array<TripCountResponse>;
  tripSpecificationList: Array<TripSpecificationResponse>;
  tripPathList: Array<TripPathResponse>;
  saveTripReqObj: Array<saveTripObject>;
}

const initialState: ProductionState = {
  isAsideVisible: false,
  globalSelectedSubModule: null,
  modules: [],
  messageStatus: null,
  tripCountReport: [],
  tripSpecificationList: [],
  tripPathList: [],
  saveTripReqObj: [],
};

export const _productionReducer = createReducer(
  initialState,
  on(
    fromProductionActions.setProductionAside,
    (state, action): ProductionState => ({
      ...state,
      isAsideVisible: action.isAsideVisible,
    })
  ),
  on(
    fromProductionActions.getProductionModulesSuccess,
    (state, action): ProductionState => {
      return {
        ...state,
        modules: action.modules,
      };
    }
  ),
  on(
    fromProductionActions.setProductionModulePreferenceFailure,
    (state, action): ProductionState => {
      return {
        ...state,
        modules: state.modules.map(module => ({ ...module })),
        messageStatus: { type: 'error', message: action.error },
      };
    }
  ),
  on(
    fromProductionActions.getProductionTripCountReportSuccess,
    (state, action): ProductionState => {
      return {
        ...state,
        tripCountReport: action.tripContResponse,
      };
    }
  ),
  on(
    fromProductionActions.getProductionTripCountReportFailure,
    (state, action): ProductionState => {
      return {
        ...state,
        messageStatus: { type: 'error', message: action.error },
      };
    }
  ),
  on(
    fromProductionActions.setProductionModulePreferenceSuccess,
    (state, action): ProductionState => {
      return {
        ...state,
        messageStatus: { type: 'success', message: action.status },
        modules: state.modules.map(module => {
          const subModuleChange = action.subModules.find(
            subModule => subModule.subModuleParamId === module.subModuleParamId
          );
          if (subModuleChange) {
            return {
              ...module,
              preferred: subModuleChange.isPreferred,
            };
          }
          return module;
        }),
      };
    }
  ),
  on(
    fromProductionActions.setProductionModulePreferenceFailure,
    (state, action): ProductionState => {
      return {
        ...state,
        modules: state.modules.map(module => ({ ...module })),
        messageStatus: { type: 'error', message: action.error },
      };
    }
  ),
  on(
    fromProductionActions.setProductionActiveAction,
    (state, action): ProductionState => {
      return {
        ...state,
        globalSelectedSubModule: action.moduleId,
      };
    }
  ),
  on(fromProductionActions.resetMessageStatus, (state): ProductionState => {
    return {
      ...state,
      messageStatus: null,
    };
  }),
  on(fromProductionActions.clearProductionStore, (state): ProductionState => {
    return {
      ...state,
      isAsideVisible: false,
      globalSelectedSubModule: null,
      modules: [],
      messageStatus: null,
    };
  }),
  on(
    fromProductionActions.getProductionTripSpecificationSuccess,
    (state, action): ProductionState => {
      return {
        ...state,
        tripSpecificationList: action.specificationRes,
      };
    }
  ),
  on(
    fromProductionActions.getProductionModulesPathListSuccss,
    (state, action): ProductionState => {
      return {
        ...state,
        tripPathList: action.data,
      };
    }
  ),
  on(
    fromProductionActions.getProductionModulesPathListFailure,
    (state, action): ProductionState => {
      return {
        ...state,
        messageStatus: { type: 'error', message: action.error },
      };
    }
  ),
  on(fromProductionActions.saveTripCount, (state, action): ProductionState => {
    return {
      ...state,
      messageStatus: {
        type: 'success',
        message: SuccessMessage.SAVE_TRIP_COUNT,
      },
      saveTripReqObj: action.saveTripReqObj,
    };
  }),
  on(
    fromProductionActions.saveTripCountFailure,
    (state, action): ProductionState => {
      return {
        ...state,
        messageStatus: { type: 'error', message: action.error },
      };
    }
  )
);

export function productionReducer(
  state: ProductionState | undefined,
  action: Action
) {
  return _productionReducer(state, action);
}
