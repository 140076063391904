import { Component, Input } from '@angular/core';
import { IChartData, ISummaryMetaDataResponse } from '../../interfaces';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent {
  private _chartData: IChartData[];
  @Input()
  set chartData(data: ISummaryMetaDataResponse[]) {
    if (data) {
      this._chartData = data
        .map((item: ISummaryMetaDataResponse) => {
          if (item.chartData !== null) {
            return {
              propertyName: item.propertyName,
              aggregatedData: this.buildAggregateChartData(
                item.chartData[0].aggregatedData
              ),
            } as unknown as IChartData;
          }
          return undefined;
        })
        .filter((item): item is IChartData => item !== undefined);
    }
  }

  get chartData(): IChartData[] {
    return this._chartData;
  }

  @Input() isLoading: boolean;

  private buildAggregateChartData(data: { [key: string]: number }): {
    [key: string]: number;
  } {
    const filledData: { [key: string]: number } = {};
    const today = DateTime.now();
    const startOfMonth = today.startOf('month');

    for (
      let date = startOfMonth;
      date <= today;
      date = date.plus({ days: 1 })
    ) {
      const formattedDate = date.toFormat('LLL dd');
      filledData[formattedDate] = data[formattedDate] || 0;
    }

    return filledData;
  }
}
