import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProductionService } from '../services';

import * as fromStore from '.';
@Injectable()
export class ProductionEffects {
  action$ = inject(Actions);
  store = inject(Store);
  productionService = inject(ProductionService);

  loadModules$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.getProductionModules),
      switchMap(() => {
        return this.productionService.getProductionModules().pipe(
          map(data => {
            const subModuleButtons = data.flatMap(subModule => {
              if (
                subModule.childSubModules &&
                subModule.childSubModules.length > 0
              ) {
                return subModule.childSubModules;
              } else {
                return subModule;
              }
            });
            return fromStore.getProductionModulesSuccess({
              modules: subModuleButtons,
            });
          }),
          catchError(error => {
            return of(error);
          })
        );
      })
    );
  });

  setProductionModulePreference$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.setProductionModulePreference),
      switchMap(action => {
        return this.productionService
          .updateSubModulePreference(action.subModules)
          .pipe(
            map(data => {
              return fromStore.setProductionModulePreferenceSuccess({
                status: data['message'],
                subModules: action.subModules,
              });
            }),
            catchError(error => {
              return of(
                fromStore.setProductionModulePreferenceFailure({
                  error: error.error.message,
                })
              );
            })
          );
      })
    );
  });

  getProductionTripCountReport$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.getProductionTripCountReport),
      switchMap(action => {
        return this.productionService
          .getProductionTripCountReport(action.req)
          .pipe(
            map(data =>
              fromStore.getProductionTripCountReportSuccess({
                tripContResponse: data,
              })
            ),
            catchError(error => {
              return of(
                fromStore.getProductionTripCountReportFailure({
                  error: error.error.message,
                })
              );
            })
          );
      })
    );
  });

  getProductionTripSpecification$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.getProductionTripSpecification),
      switchMap(action => {
        return this.productionService
          .getProductionTripSpecification(action.specificationReq)
          .pipe(
            map(data =>
              fromStore.getProductionTripSpecificationSuccess({
                specificationRes: data,
              })
            ),
            catchError(error => {
              return of(
                fromStore.getProductionTripSpecificationSuccessFailure({
                  error: error.error.message,
                })
              );
            })
          );
      })
    );
  });

  getProductionTripPath$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.getProductionModulesPathList),
      switchMap(action => {
        return this.productionService.getTripPath().pipe(
          map(data =>
            fromStore.getProductionModulesPathListSuccss({
              data: data,
            })
          ),
          catchError(error => {
            return of(
              fromStore.getProductionModulesPathListFailure({
                error: error.error.message,
              })
            );
          })
        );
      })
    );
  });

  saveTripCount$ = createEffect(() => {
    return this.action$.pipe(
      ofType(fromStore.saveTripCount),
      switchMap(data => {
        return this.productionService
          .saveTripCountRecord(data.saveTripReqObj)
          .pipe(
            map(data =>
              fromStore.saveTripCountSuccess({ saveTripReqObj: [data] })
            ),
            catchError(error => {
              return of(
                fromStore.saveTripCountFailure({
                  error: error.error.message,
                })
              );
            })
          );
      })
    );
  });
}
