import { Component, inject } from '@angular/core';
import { AgCellRendererBase } from '../ag-cell-renderer-base';
import { AgCellRendererEvent } from '../ag-cell-renderer.event';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss'],
})
export class CrudComponent extends AgCellRendererBase {
  isEditing = false;
  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);

  onView(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.VIEW_EVENT, event);
  }

  onEdit(event: MouseEvent) {
    this.isEditing = true;
    this.sendEvent(AgCellRendererEvent.EDIT_EVENT, event);
  }

  onDelete(event: MouseEvent) {
    this.showPopConfirm(
      event,
      'Are you sure you want to delete this data?',
      AgCellRendererEvent.DELETE_EVENT
    );
  }

  onConfiguration(event: MouseEvent) {
    this.sendEvent(AgCellRendererEvent.CONFIGURE, event);
  }

  onUpdate(event: MouseEvent) {
    this.isEditing = false;
    this.sendEvent(AgCellRendererEvent.UPDATE_EVENT, event);
  }

  onCancel(event: MouseEvent) {
    this.isEditing = false;
    this.sendEvent(AgCellRendererEvent.CANCEL_EVENT, event);
  }

  showPopConfirm(event: MouseEvent, message: string, eventType: string) {
    this.confirmationService.confirm({
      target: event.currentTarget as HTMLElement,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sendEvent(eventType, event);
      },
    });
  }
}
