import { MapService } from './../services/map.service';
import { Component, OnInit } from '@angular/core';
import { ISubModuleResponse } from 'src/app/shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { Geofence, MapActionViewTypes } from '../interfaces/map.interfaces';

@Component({
  selector: 'app-home-map',
  templateUrl: './home-map.component.html',
  styleUrls: ['./home-map.component.scss'],
})
export class HomeMapComponent implements OnInit {
  fencelist$ = new BehaviorSubject<Geofence[]>([]);
  isFenceEnableOnMap = new BehaviorSubject<boolean>(false);

  viewTypes: MapActionViewTypes[] = ['home', 'map', 'side'];
  currentSelectedView: MapActionViewTypes;
  currentSelectedSubModule: number | null;
  modules: Array<ISubModuleResponse> = [];

  constructor(private mapService: MapService) {}

  ngOnInit(): void {
    this.mapService.getGeoFence().subscribe(
      recevdFenceList => {
        this.fencelist$.next(recevdFenceList); // Update the fencelist with the fetched data
      },
      error => {
        console.error('Error fetching geofences:', error);
      }
    );
  }

  onGeoFenceToggle(enabled: boolean) {
    this.isFenceEnableOnMap.next(enabled);
  }
}
