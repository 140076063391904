import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromAsset from '../asset/store/asset.reducer';
import * as fromFleet from '../fleet/store/fleet.reducer';
import * as fromFuel from '../fuel/store/fuel.reducer';
import * as fromMaintenance from '../maintenance/store/maintenance.reducer';
import * as fromProduction from '../production/store/production.reducer';

export interface AppState {
  auth: fromAuth.AuthState;
  asset: fromAsset.AssetState;
  fleet: fromFleet.FleetState;
  fuel: fromFuel.FuelState;
  maintenance: fromMaintenance.MaintenanceState;
  production: fromProduction.ProductionState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  asset: fromAsset.assetReducer,
  fleet: fromFleet.fleetReducer,
  fuel: fromFuel.fuelReducer,
  maintenance: fromMaintenance.maintenanceReducer,
  production: fromProduction.productionReducer,
};
