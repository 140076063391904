import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

import { IAuth, ILogin } from '../interfaces';
import { StorageService } from './../../shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  localStorageService = inject(StorageService);
  jwtHelper = inject(JwtHelperService);
  apiErrorService = inject(ApiErrorService);
  private tokenTimer: NodeJS.Timer;

  login(data: ILogin): Observable<IAuth> {
    return this.http
      .post<IAuth>(`${environment.baseUrl}/auth/login`, data)
      .pipe(catchError(this.apiErrorService.handleError));
  }

  handleLogin(data: IAuth): void {
    this.saveAuthDataToLocalStorage(data.token);
    this.onSuccessfulAuthentication(data.expiration);
  }

  logout() {
    clearTimeout(this.tokenTimer as unknown as number);
    this.clearAuthDataFromLocalStorage();
  }
  onSuccessfulAuthentication(expiration: Date): void {
    const expiresIn = this.calcAuthExpirationForTimer(expiration);
    const expiresInMs = expiresIn;
    this.setAuthTimer(expiresInMs);
  }

  getAuthDataFromLocalStorage(): IAuth | null {
    const token = this.localStorageService.loadAuthToken();
    if (token) {
      return this.extractUserDataFromToken(token);
    } else {
      return null;
    }
  }

  getTokenFromLocalStorage(): string | null {
    const user = this.getAuthDataFromLocalStorage();
    if (!user) {
      return null;
    }
    return user.token;
  }

  extractUserDataFromToken(token: string): IAuth | null {
    const decodedToken = this.jwtHelper.decodeToken(token);
    const expiry = this.jwtHelper.getTokenExpirationDate(token);
    if (
      decodedToken !== null &&
      expiry !== null &&
      !this.jwtHelper.isTokenExpired(token)
    ) {
      return {
        roles: decodedToken.Roles,
        userId: decodedToken.sub,
        userName: decodedToken.userName,
        token: token,
        expiration: expiry,
      };
    } else {
      return null;
    }
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }
  private clearAuthDataFromLocalStorage(): void {
    this.localStorageService.clearAuthToken();
  }
  private saveAuthDataToLocalStorage(token: string): void {
    this.localStorageService.saveAuthToken(token);
  }

  private calcAuthExpirationForTimer(expirationDate: Date): number {
    const now = new Date();
    const expiresIn = expirationDate.getTime() - now.getTime();
    return expiresIn;
  }
}
