export interface RoleResponse {
  roleId: number;
  roleName: string;
  roleDesc: string;
  clientId: number;
  managedBy: string;
  createdBy: string;
}

export interface IClient {
  clientId: number;
  clientName: string;
}

export interface SubModuleDetail {
  id?: number;
  subModuleId: number;
  subModuleName: string;
  roleName: string;
  description: string;
  mobile: boolean;
  userPermission: PERMISSION_TYPE;
  rolePermission?: PERMISSION_TYPE;
}

export interface SubModuleCollection {
  isAllChecked?: boolean;
  subModules: SubModuleDetail[];
}

export interface ModulePermissions {
  moduleNames: string[];
  subModuleGroup: { [key: string]: SubModuleCollection };
}

export interface SubModuleUpdateReqBody {
  id?: number;
  subModuleId: number;
  rolePermission?: PERMISSION_TYPE;
}

export interface ToggleSubModuleEvent {
  subModule: SubModuleDetail;
  isSelected: boolean;
}

export enum PERMISSION_TYPE {
  READ = 'READ',
  WRITE = 'WRITE',
  DELETE = 'DELETE',
}
