import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { PasscodeComponent } from '../passcode/passcode.component';
import { IPasscode } from '../interfaces';
import { StorageService } from './storage.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorService } from 'src/app/core/services/api-error.service';

@Injectable({
  providedIn: 'root',
})
export class PasscodeService {
  http = inject(HttpClient);
  dialogService = inject(DialogService);
  ref: DynamicDialogRef | undefined;
  localStorageService = inject(StorageService);
  location = inject(Location);
  router = inject(Router);
  route = inject(ActivatedRoute);
  apiErrorService = inject(ApiErrorService);

  verifyPasscode(data: {
    subModuleId: number;
    passcode?: number;
    subModulePasscodeId?: number;
  }): Observable<IPasscode> {
    const requestUrl = `${environment.baseUrl}/sub-module-passcode/verify`;
    return this.http
      .get<IPasscode>(requestUrl, { params: this.getPasscodeParams(data) })
      .pipe(catchError(this.apiErrorService.handleError));
  }

  openPasscodeDialog(subModuleId: number): Promise<boolean> {
    return new Promise<boolean>((resolve: (value: boolean) => void) => {
      this.ref = this.dialogService.open(PasscodeComponent, {
        header: 'Enter Passcode',
        width: 'auto',
        contentStyle: { overflow: 'auto' },
        data: { subModuleId: subModuleId },
      });
      this.ref.onClose.subscribe((res: IPasscode) => {
        if (res) {
          this.localStorageService.savePasscodeOperatorData(res);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  checkLoggedInPasscode(subModuleId: number): Promise<boolean> {
    return new Promise<boolean>((resolve: (value: boolean) => void) => {
      const operatorData = this.getPasscodeOperatorDataFromLocalSotrage();
      if (operatorData !== null) {
        const data = {
          subModuleId: subModuleId,
          subModulePasscodeId: operatorData.operatorId,
        };
        this.verifyPasscode(data).subscribe({
          next: res => {
            if (res) {
              this.localStorageService.savePasscodeOperatorData(res);
              resolve(true);
            }
          },
          error: () => {
            this.openPasscodeDialog(subModuleId).then(res => resolve(res));
          },
        });
      } else {
        this.openPasscodeDialog(subModuleId).then(res => resolve(res));
      }
    });
  }

  getPasscodeOperatorDataFromLocalSotrage(): {
    operatorId: number;
    operatorName: string;
  } | null {
    const operatorData = this.localStorageService.loadPasscodeOperatorData();
    if (operatorData !== null) {
      const operator = JSON.parse(operatorData);
      return {
        operatorId: operator.operatorId,
        operatorName: operator.operatorName,
      };
    } else {
      return null;
    }
  }

  private getPasscodeParams(data: {
    subModuleId: number;
    passcode?: number;
    subModulePasscodeId?: number;
  }) {
    const params = new HttpParams().append(
      'subModuleId',
      data.subModuleId.toString()
    );
    if (data.subModulePasscodeId !== undefined) {
      return params.append('subModulePasscodeId', data.subModulePasscodeId);
    } else if (data.passcode !== undefined) {
      return params.append('passcode', data.passcode);
    } else {
      return params;
    }
  }
}
