import {
  Component,
  OnDestroy,
  OnInit,
  inject,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';
import { TreeNode } from 'primeng/api';
import * as fromStore from '../store';
import { AssetService } from './../services/asset.service';
import { AssetGrpByClassBySite } from '../interfaces/asset.interface';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent implements OnInit, OnDestroy {
  router = inject(Router);
  store = inject(Store);
  asset = inject(AssetService);

  @Input({ required: true }) set selectionMode(
    mode: 'single' | 'multiple' | 'checkbox'
  ) {
    this.selectedMode = mode;

    if (mode === 'multiple') {
      this.selectedNodes = [];
    }
  }

  @Output() toggleAssetDetailsWidth = new EventEmitter<boolean>();
  @Output() nodeSelectionChange = new EventEmitter<{
    type: string;
    values: string[];
  }>();
  private assetsListSub$: Subscription;
  assetsList: TreeNode[] = [];
  isAsideVisible = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedMode: 'single' | 'multiple' | 'checkbox';
  selectedNodes: any;

  ngOnInit() {
    this.assetsListSub$ = this.store
      .select(fromStore.selectAssetsGroupByClass)
      .pipe(map((data: AssetGrpByClassBySite) => this.convertToTreeNode(data)))
      .subscribe(data => {
        this.assetsList = data;
      });
    this.store.dispatch(fromStore.getAllAssets());
  }

  onSelectionChange(value: TreeNode | TreeNode[] | null): void {
    if (
      this.selectedMode === 'single' &&
      (value as TreeNode)?.type == 'asset'
    ) {
      const assetId = (value as TreeNode)?.data.id;
      if (assetId) {
        this.store.dispatch(fromStore.getAssetById({ assetId }));
      }
      return;
    }

    if (value) {
      this.updateTreeSelection(value as TreeNode[]);
      const selectedTreeNodes = this.getSelectedAssetByType();
      this.nodeSelectionChange.emit(selectedTreeNodes);
    }
  }

  toggleAside(): void {
    this.isAsideVisible = !this.isAsideVisible;
    this.toggleAssetDetailsWidth.emit(this.isAsideVisible);
  }

  private convertToTreeNode(data: AssetGrpByClassBySite): TreeNode[] {
    const treeNodes: TreeNode[] = [];
    let siteCounter = 0;
    for (const siteName in data) {
      const siteNode: TreeNode = {
        key: siteCounter.toString(),
        label: siteName,
        children: [],
        type: 'site',
        expanded: true,
        selectable: false,
      };

      let assetClassCounter = 0;
      for (const assetClassName in data[siteName]) {
        const assetClassNode: TreeNode = {
          key: `${siteCounter}-${assetClassCounter}`,
          label: assetClassName,
          type: 'assetClass',
          expanded: true,
          children: data[siteName][assetClassName].map(asset => ({
            key: `${siteCounter}-${assetClassCounter}-${asset.id}`,
            label: asset.name,
            data: asset,
            type: 'asset',
          })),
        };
        if (siteNode && siteNode.children) {
          siteNode.children.push(assetClassNode);
        }
        assetClassCounter++;
      }
      siteCounter++;
      treeNodes.push(siteNode);
    }
    return treeNodes;
  }

  /**
   * Updates the tree selection based on the provided value.
   *
   * @param value - The new selection value. It should be an array of TreeNode objects.
   *
   * The function performs the following actions:
   * - Retrieves the last selected TreeNode from the value.
   * - If the selected TreeNode is of type 'assetClass', it sets the selectedNodes array to contain only this node.
   * - If the selected TreeNode is of type 'asset', it removes any existing 'assetClass' nodes from the selectedNodes array and adds the selected TreeNode to the array.
   */
  private updateTreeSelection(value: TreeNode[]): void {
    const currentSelection = (value as TreeNode[]).pop();
    if (currentSelection) {
      if (currentSelection.type === 'assetClass') {
        this.selectedNodes = [currentSelection];
      } else if (currentSelection.type === 'asset') {
        const assetClassNodeIndex = this.selectedNodes.findIndex(
          (node: TreeNode) => node.type === 'assetClass'
        );
        if (assetClassNodeIndex !== -1) {
          this.selectedNodes.splice(assetClassNodeIndex, 1);
        }
        this.selectedNodes.push(currentSelection);
      }
    }
  }
  /**
   * Updates the tree selection based on the provided value.
   *
   *
   * The function performs the following actions:
   * - As we already know from updateTreeSelection function it will contain only one value if node.type is 'assetClass' and it can conatin multiple values if node.type is 'asset'.
   * so the function will iterate over the selectedNodes array and get the label of each node and return the type and values as an object.
   */
  private getSelectedAssetByType(): { type: string; values: string[] } {
    const labels: string[] = [];
    let type = '';

    this.selectedNodes.forEach((node: TreeNode) => {
      if (node) {
        if (node.type === 'assetClass' && node.label) {
          labels.push(node.label);
          type = node.type;
        } else if (node.type === 'asset' && node.data) {
          labels.push(node.data.id);
          type = node.type;
        }
      }
    });

    return {
      type,
      values: labels,
    };
  }
  ngOnDestroy(): void {
    if (this.assetsListSub$) {
      this.assetsListSub$.unsubscribe();
    }
  }
}
