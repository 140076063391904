<div class="btn">
  <a
    *ngIf="viewButton"
    (click)="onView($event)"
    matTooltip="View"
    triggers="mouseenter:mouseleave"
    container="body">
    <i class="fa fa-eye"></i>
  </a>
  <ng-template #notEditing>
    <a
      *ngIf="editButton"
      (click)="onEdit($event)"
      matTooltip="Edit"
      triggers="mouseenter:mouseleave"
      container="body">
      <i class="far fa-edit"></i>
    </a>
    <a
      (click)="onDelete($event)"
      matTooltip="Delete"
      triggers="mouseenter:mouseleave"
      container="body">
      <i class="fa fa-trash-alt"></i>
    </a>
    <a
      *ngIf="configureButton"
      (click)="onConfiguration($event)"
      matTooltip="Configure"
      triggers="mouseenter:mouseleave"
      container="body">
      <i class="fa fa-cogs"></i>
    </a>
  </ng-template>
  <ng-container *ngIf="isEditing; else notEditing">
    <a
      (click)="onUpdate($event)"
      matTooltip="Update"
      triggers="mouseenter:mouseleave"
      container="body">
      <i class="fa fa-check"></i>
    </a>
    <a
      (click)="onCancel($event)"
      matTooltip="Cancel"
      triggers="mouseenter:mouseleave"
      container="body">
      <i class="fa fa-times"></i>
    </a>
  </ng-container>
</div>
<p-confirmPopup></p-confirmPopup>
