import { ApiErrorService } from './../../core/services/api-error.service';
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeofenceDTO } from '../interfaces/map.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  http = inject(HttpClient);
  apiErrorService = inject(ApiErrorService);

  getGeoFence(): Observable<GeofenceDTO[]> {
    const requestUrl = `${environment.baseUrl}/map/geofence`;
    return this.http
      .get<GeofenceDTO[]>(requestUrl)
      .pipe(catchError(this.apiErrorService.handleError));
  }
}
