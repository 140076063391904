import { PERMISSION_TYPE } from '../interfaces/roles-interface';
import { SelectOptions } from '../interfaces/configuration.interfaces';

export const ROLE_BASE_URL = 'configuration/home/role';
export const USER_BASE_URL = 'configuration/home/user';
export const ASSET_CLASS_BASE_URL = 'configuration/home/asset-class';
export const PRE_CHECK_BASE_URL = 'configuration/home/pre-check-config';
export const TAG_BASE_URL = 'configuration/home/tags';
export const ASSET_BASE_URL = 'configuration/home/assets';

export const PERMISSIONS_LIST = [
  PERMISSION_TYPE.READ,
  PERMISSION_TYPE.WRITE,
  PERMISSION_TYPE.DELETE,
];

export enum TAG_TYPE {
  ASSET_TAG = 'ASSET_TAG',
  SENSOR_TAG = 'SENSOR_TAG',
}

export const TAG_TYPE_OPTIONS: SelectOptions[] = [
  { label: 'Asset', value: TAG_TYPE.ASSET_TAG },
  { label: 'Sensor', value: TAG_TYPE.SENSOR_TAG },
];

export const TAG_INPUT_TYPES = {
  INPUT: 'INPUT',
  SELECT: 'SELECT',
  CHIPS: 'CHIPS',
};

export const TAG_VALUE_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
};

export const BOOLEAN_OPTIONS: SelectOptions[] = [
  { label: 'No', value: 'false' },
  { label: 'Yes', value: 'true' },
];

export const PAGINATION_PAGE_SIZE_SELECTOR = [25, 50, 100];

export const TOP_ROLES = {
  SUPERUSER: 'SUPERUSER',
  ROOT: 'ROOT',
};

export const assetStatus: string[] = [
  'Engine ON',
  'Engine OFF',
  'Breakdown',
  'Parked',
  'Under Review',
  'Idle',
];

export const ASSET_FILE_CATG = {
  PROFILE_PICTURE: 'Profile Picture',
};

export const PRE_CHECK_VALUE_TYPE_OPTIONS: SelectOptions[] = [
  { label: 'Checked', value: 'CHECKED' },
  { label: 'Number', value: 'NUMBER' },
];

export const CHECKUP_TYPE_OPTIONS: SelectOptions[] = [
  { label: 'Prestart Checkup', value: 'Prestart-Checkup' },
  { label: 'Service Checkup', value: 'Service-Checkup' },
];

export const ASSET_IMP_TAGS = {
  FUEL_SENSOR_INSTALLED: 'Fuel Sensor Installed',
  TPMS_INSTALLED: 'Tpms Installed',
};
