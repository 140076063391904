<div class="dropdown-container">
  <p-dropdown
    class="custom-dropdown"
    #dropdown
    [options]="options"
    [(ngModel)]="selectedOption"
    optionLabel="name"
    placeholder="Select an option"
    [style]="{ width: '100%', height: '100%' }"
    [appendTo]="'body'">
  </p-dropdown>
</div>
