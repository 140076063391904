<div class="app-container">
  <div class="app-header">
    <app-header icon="fa-regular fa-map" title="Map"></app-header>
  </div>
  <div class="app-actions">
    <app-actions
      [availableViews]="viewTypes"
      [selectedView]="currentSelectedView"
      [selectedSubModule]="currentSelectedSubModule"
      [modules]="modules"></app-actions>
  </div>
  <div class="app-main">
    <app-asset-list [selectionMode]="'multiple'"></app-asset-list>
    <div class="app-component">
      <app-map-toolbar
        (isGeoFenceEnabled)="onGeoFenceToggle($event)"></app-map-toolbar>
      <app-google-map
        [fenceList]="fencelist$"
        [isEnableOnMap]="isFenceEnableOnMap"></app-google-map>
    </div>
  </div>
</div>
