<div class="container">
  <div class="container__left">
    <div class="container__header-icon">
      <i class="{{ icon }}"></i>
    </div>
    <div class="container__header-content">
      <div class="title">{{ title }}</div>
      <div class="breadcrumb">
        <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
        <ng-template #iconTemplate>
          <i class="fa-light fa-angle-right"></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="container__right">
    <div class="container__right-content">
      <div class="container__input-action-content">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            placeholder="Search asset, asset class or site name" />
        </span>
        <div class="container__icons">
          <div class="container__badge">
            <i class="pi pi-bell" pBadge value="2"></i>
          </div>
          <div class="pointer-cursor" (click)="menu.toggle($event)">
            <i class="fa-light fa-user"></i>
          </div>
        </div>
      </div>
      <div class="container__action-menu">
        <p-tabMenu class="header-tab-btn" [model]="actionItems"></p-tabMenu>
      </div>
    </div>
  </div>
</div>

<p-menu #menu [model]="userMenuItems" [popup]="true">
  <ng-template pTemplate="item" let-item>
    <div class="p-menuitem-link">
      <i [class]="item.icon"></i>
      <span> {{ item.label }}</span>
    </div>
  </ng-template>
</p-menu>
