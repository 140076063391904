import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import {
  ISubModuleResponse,
  ISubModulePreferenceRequest,
} from 'src/app/shared/interfaces';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent {
  @Input({ required: true }) availableViews: string[] = [];
  @Input({ required: true }) selectedView: string;
  @Input({ required: true }) selectedSubModule: number | null;
  @Input({ required: true }) modules: Array<ISubModuleResponse>;
  @Input() showAddButton = true;
  @Output() clickViewChange = new EventEmitter<string>();
  @Output() changeActionSelection =
    new EventEmitter<ISubModulePreferenceRequest>();
  @Output() changeNavigationOnAction = new EventEmitter<number>();
  @Output() pageViewChange = new EventEmitter<boolean>();
  pageViewOptions = [
    {
      id: 1,
      key: 'table',
      label: 'Table View',
      image: 'table_view_icon',
    },
    {
      id: 2,
      key: 'list',
      label: 'List View',
      image: 'list_view_icon',
    },
  ];

  selectedPageView: string | undefined;
  onViewChange(view: string): void {
    this.selectedView = view;
    this.clickViewChange.emit(this.selectedView);
  }

  onPageViewChange(event: DropdownChangeEvent): void {
    const isDashboard = event.value.key === 'table';
    this.pageViewChange.emit(isDashboard);
  }
  onActionSelectionChange(submodule: ISubModuleResponse): void {
    this.changeActionSelection.emit({
      subModuleParamId: submodule.subModuleParamId,
      isPreferred: !submodule.preferred,
    });
  }

  onActionNavigate(moduleId: number): void {
    this.changeNavigationOnAction.emit(moduleId);
  }
}
