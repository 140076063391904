<highcharts-chart
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  [callbackFunction]="chartCallback"
  [ngClass]="chart_width"></highcharts-chart>

<p-button
  icon="fa-duotone fa-solid fa-plus-large"
  [rounded]="true"
  [text]="true"
  (onClick)="onReflow()" />
