import { createAction, props } from '@ngrx/store';
import { IAsset, IAssetReq } from '../interfaces/asset.interface';
import { ITagValue } from 'src/app/configuration/interfaces/configuration.interfaces';

const RESET_ASSET_MESSAGE_STATUS = '[Asset] Reset message status';
const GET_ALL_ASSETS = '[Asset] Get All Assets';
const GET_ALL_ASSETS_SUCCESS = '[Asset] Get All Assets Success';
const GET_ALL_ASSETS_FAILED = '[Asset] Get All Assets Failed';
const GET_ASSET_BY_ID = '[Asset] Get Asset By Id';
const GET_ASSET_BY_ID_SUCCESS = '[Asset] Get Asset By Id Success';
const GET_ASSET_BY_ID_FAILURE = '[Asset] Get Asset By Id Failure';
const CREATE_NEW_ASSET = '[Asset] Create New Asset';
const CREATE_NEW_ASSET_SUCCESS = '[Asset] Create New Asset Success';
const CREATE_NEW_ASSET_FAILED = '[Asset] Create New Asset Failed';
const UPDATE_ASSET = '[Asset] Update Asset';
const UPDATE_ASSET_SUCCESS = '[Asset] Update Asset Success';
const UPDATE_ASSET_FAILED = '[Asset] Update Asset Failed';
const DELETE_ASSET = '[Asset] Delete Asset';
const DELETE_ASSET_SUCCESS = '[Asset] Delete Asset Success';
const DELETE_ASSET_FAILED = '[Asset] Delete Asset Failed';
const UPDATE_ASSET_STATUS = '[Asset] Update Asset Status';
const UPDATE_ASSET_STATUS_SUCCESS = '[Asset] Update Asset Status Success';
const UPDATE_ASSET_STATUS_FAILED = '[Asset] Update Asset Status Failed';
const UPLOAD_ASSET_IMAGE = '[Asset] Upload Asset Image';
const UPLOAD_ASSET_IMAGE_SUCCESS = '[Asset] Upload Asset Image Success';
const UPLOAD_ASSET_IMAGE_FAILED = '[Asset] Upload Asset Image Failed';
const UPDATE_TAG_VALUES_FAILED = '[Asset] Update Tag Values Failed';
const CLEAR_ASSET_STORE = '[Asset] Clear Asset Store';

export const resetMessageStatus = createAction(RESET_ASSET_MESSAGE_STATUS);

export const getAllAssets = createAction(GET_ALL_ASSETS);

export const getAllAssetsSuccess = createAction(
  GET_ALL_ASSETS_SUCCESS,
  props<{ assetsList: IAsset[] }>()
);

export const getAllAssetsFailed = createAction(
  GET_ALL_ASSETS_FAILED,
  props<{ message: string }>()
);

export const getAssetById = createAction(
  GET_ASSET_BY_ID,
  props<{ assetId: number; shouldNavigate?: boolean }>()
);

export const getAssetByIdSuccess = createAction(
  GET_ASSET_BY_ID_SUCCESS,
  props<{ asset: IAsset; shouldNavigate?: boolean }>()
);

export const getAssetByIdFailure = createAction(
  GET_ASSET_BY_ID_FAILURE,
  props<{ message: string }>()
);

export const createNewAsset = createAction(
  CREATE_NEW_ASSET,
  props<{ assetData: IAssetReq; tags: ITagValue[] }>()
);

export const createNewAssetSuccess = createAction(
  CREATE_NEW_ASSET_SUCCESS,
  props<{ asset: IAsset }>()
);

export const createNewAssetFailed = createAction(
  CREATE_NEW_ASSET_FAILED,
  props<{ message: string }>()
);

export const updateAsset = createAction(
  UPDATE_ASSET,
  props<{ assetId: number; asset: IAssetReq }>()
);

export const updateAssetSuccess = createAction(
  UPDATE_ASSET_SUCCESS,
  props<{ asset: IAsset }>()
);

export const updateAssetFailed = createAction(
  UPDATE_ASSET_FAILED,
  props<{ message: string }>()
);

export const deleteAsset = createAction(
  DELETE_ASSET,
  props<{ assetId: number }>()
);

export const deleteAssetSuccess = createAction(
  DELETE_ASSET_SUCCESS,
  props<{ assetId: number }>()
);

export const deleteAssetFailed = createAction(
  DELETE_ASSET_FAILED,
  props<{ message: string }>()
);

export const updateAssetStatus = createAction(
  UPDATE_ASSET_STATUS,
  props<{ asset: IAsset }>()
);

export const updateAssetStatusSuccess = createAction(
  UPDATE_ASSET_STATUS_SUCCESS,
  props<{ newStatus: { [key: number]: string } }>()
);

export const updateAssetStatusFailed = createAction(
  UPDATE_ASSET_STATUS_FAILED,
  props<{ message: string }>()
);

export const uploadAssetImage = createAction(
  UPLOAD_ASSET_IMAGE,
  props<{ assetId: number; files: File[] }>()
);

export const uploadAssetImageSuccess = createAction(
  UPLOAD_ASSET_IMAGE_SUCCESS,
  props<{ imgUrls: string[] }>()
);

export const uploadAssetImageFailed = createAction(
  UPLOAD_ASSET_IMAGE_FAILED,
  props<{ message: string }>()
);

export const updateAssetTagValuesFailed = createAction(
  UPDATE_TAG_VALUES_FAILED,
  props<{ asset?: IAsset; message: string }>()
);

export const clearAssetStore = createAction(CLEAR_ASSET_STORE);
